<template>
    <div class="modal-bg" v-show="isShow">
        <div class="modal-container">
            <div class="modal-top">
                <span @click="hideModal"><img class="close-modal-svg" src="../../assets/svg/x_icon.svg" /></span>
            </div>
            <div class="modal-header">
                {{ $t('modal.hold_desc') }} {{ projectInfo.bonus_limit_amount }}{{ projectInfo.snapshot_coin }}
                {{ $t('modal.Smart_desc') }}
                {{ $t('modal.Rewards_desc') }} {{ projectInfo.bonus_limit_amount }}{{ projectInfo.snapshot_coin }}.
                {{ $t('modal.address_desc') }} {{ projectInfo.snapshot_coin }} {{ $t('modal.hours_desc') }}
            </div>
            <div class="modal-body">
                <div class="modal-body-header">
                    <vue-qr v-if="userInfo.qrUrl" :text="userInfo.qrUrl" :size="100" />
                </div>
                <div class="modal-body-main">
                    <div class="modal-body-mainheader" @click="copylink">
                        {{ userInfo.qrUrl }}
                    </div>
                </div>
            </div>
            <div class="modal-btn">
                <!-- <button class="btn btn-primary rounded-pill" @click="submit()">提交</button> -->
                <el-button class="btn-primary" @click="copylink">{{ $t('modal.copyLink_desc') }}</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import VueQr from 'vue-qr';
export default {
    name: 'Modal',
    props: {
        isShow: {
            type: Boolean,
        },
        title: {
            default: ''
        },
        userInfo: {
            qrUrl: 'http://www.google.com',
            // dialogInfo:"Web3.js是一个用于与以太坊网络进行交互的JavaScript库。它提供了一系列的API和工具,用于连接到以太坊网络、管理账户、发送交易、调用智能合约等操作。",
            // imageUrl:require('../assets/svg/fdusd-card-thumb.png'),
        },
        projectInfo: {}
    },
    components: {
        'vue-qr': VueQr,
    },
    methods: {
        submit() {
            this.$emit('submit')
        },
        hideModal() {
            this.$emit('close')
        },
        copylink() {
            const textArea = document.createElement("textarea");
            textArea.value = this.userInfo.qrUrl;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            this.$message.success('Copied');
        },

    },

}
</script>
<style lang="scss" scoped>
.modal-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .35);
    z-index: 2;

    .modal-container {
        width: 20rem;
        position: fixed;
        overflow: hidden;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 1);
        border-radius: 20px;
        top: 50%;
        left: 50%;

        .modal-top {
            color: #a5a2a2;
            text-align: right;
            margin: 1rem 1rem 0 0;

        }
    }

    @media(max-width: 688px) {
        .modal-container {
            width: calc(100vw/1.2);
        }

        .modal-body {
            height: calc(100vh/4);
        }
    }

    .modal-header {
        margin: 0 1rem;
        color: ghostwhite;
        font-size: 16px;
        text-align: left;
        text-indent: 2em;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: center;
        .modal-body-main {
            width: 12rem;
            margin: 2rem 4rem;
            background-color: #fff;
            border-radius: 0.6rem;
            text-align: center;
        }
    }

    .modal-btn {
        text-align: center;

        .btn-primary {
            background-color: #00D682;
            width: 8rem;
            height: 3rem;
            border-radius: 30px;
            color: black;
            border: #00D682;
        }

        margin: 0 1rem 1rem 1rem;
    }

    .modal-body-header {
        margin-top: 1rem;
        height: 100%;
    }

    .close-modal-svg {
        cursor: pointer;
        background: white;
    }
}
</style>