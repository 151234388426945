const en_ES = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'idioma',
        fduse_desc: 'FDUSD es una Stablecoin respaldada por USD en una proporción de 1:1.',
        introduce_desc: 'Revolucionamos las finanzas globales con una Stablecoin digital de vanguardia, brindando seguridad, velocidad e innovación a empresas y constructores en todo el mundo.',
        getfduse_desc: 'Obtén FDUSD',
        stability_desc: 'Estabilidad y Innovación',
        experience_desc: 'Experimenta velocidades rápidas y costos mínimos. Da forma al futuro de las finanzas con nuestra solución innovadora y confiable.',
        whitepaper_desc: 'Libro Blanco',
        redeemable_desc: 'Canjeable 1:1 por dólares estadounidenses',
        dollars_desc: 'FDUSD tiene la intención de estar respaldado por completo por efectivo y activos equivalentes en efectivo. Se pretende que los tokens sean canjeables 1:1 por dólares estadounidenses.',
        backed_desc: 'Respaldado por activos totalmente reservados',
        deserved_desc: 'Los activos reservados se mantienen en estructuras de custodia totalmente segregadas y protegidas contra la bancarrota.',
        programmable_desc: 'Totalmente programable',
        innovative_desc: 'Con nuestra solución innovadora de Stablecoin respaldada por tecnología avanzada, buscamos proporcionar una alternativa segura y confiable que brinde tranquilidad y tenga un impacto positivo en el mundo de las finanzas.',
        settle_desc: 'Liquidación en minutos',
        nearZero_desc: 'Costo cercano a cero',
        always_desc: 'Siempre activo, 24/7',
        fully_desc: "Totalmente programable",
        rewrite_desc: 'Reescribe el futuro con First Digital Labs',
        combining_desc: 'Combinando estabilidad con innovación en First Digital Labs, nos comprometemos a ganar confianza ofreciendo transparencia inquebrantable, cumplimiento normativo y seguridad respaldados por tecnología de vanguardia.',
        mission_desc: 'Tenemos la misión de impulsar la industria financiera hacia una nueva era de las finanzas y remodelar el panorama financiero global.',
        should_desc: '¿Por qué deberías usar First Digital USD?',
        collateral_desc: 'La garantía está protegida por un custodio de primer nivel y calificado con una licencia fiduciaria para una protección de activos sin problemas y cumplimiento normativo.',
        transparent_desc: 'Transparente',
        independent_desc: 'Atestación independiente: la garantía es validada por un tercero independiente, garantizando la integridad de las reservas y los informes financieros.',
        examined_by_desc: 'Examinado por',
        attestation_Reports_desc: 'Informes de Attestation',
        January2024_desc: 'Enero 2024',
        Report_desc: 'Informe',
        December_desc: 'Diciembre 2023',
        November_desc: 'Noviembre 2023',
        Networks_desc: '¿Qué redes son compatibles?',
        available_desc: 'FDUSD está disponible en',
        Ethereum_desc: 'Ethereum',
        and_desc: 'y',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'con soporte planificado para un número creciente de blockchains.',
        Audited_desc: 'Auditado por',
        Frequently_desc: 'Preguntas Frecuentes',
        How_FDUSD_desc: '¿Cómo puedo obtener FDUSD?',
        Acquiring_desc: 'Puedes obtener FDUSD de varias formas. Si eres un actor importante de la industria, un intermediario financiero o un inversionista profesional de cierto estatus y cumples con los requisitos necesarios, puedes adquirir FDUSD directamente de First Digital Labs. Ten en cuenta que First Digital Labs no vende tokens directamente a clientes minoristas:',
        canstill_desc: 'Todavía puedes obtener FDUSD a través de mercados secundarios sin convertirte en cliente de First Digital Labs. Muchos exchanges líderes de criptomonedas listan FDUSD y ofrecen una liquidez sustancial. Esto proporciona una forma accesible y conveniente para que las personas interesadas adquieran FDUSD. Sin embargo, siempre es esencial realizar una investigación exhaustiva y asegurarse de la credibilidad del exchange que estés utilizando.',
        equivalent_desc: '¿Cómo puedo confiar en que FDUSD siempre será equivalente a un dólar?',
        stablecoin_desc: 'Tu confianza en el valor de la Stablecoin FDUSD está bien fundamentada. Se pretende que cada FDUSD esté respaldado por completo por un dólar estadounidense o un activo de valor justo equivalente. Estos activos se mantienen de manera segura por uncustodio calificado en cuentas con instituciones depositarias reguladas, lo que proporciona una base sólida para el valor de la moneda. Este sistema es lo que brinda a FDUSD su estabilidad inherente y por qué puedes confiar en su equivalencia con el dólar estadounidense.',
        redeem_FDUSD: '¿Cómo puedo canjear FDUSD?',
        stablecoin_desc: 'Para canjear tu Stablecoin FDUSD, primero debes convertirte en cliente de First Digital Labs y cumplir con requisitos específicos, incluyendo verificación contra el lavado de dinero (AML) y financiamiento del terrorismo (CTF). Una vez completada exitosamente esta verificación, puedes intercambiar tus FDUSD por su equivalente en moneda fiduciaria, sacándolo de circulación. Alternativamente, puedes vender tus tokens FDUSD en el mercado secundario a través de un exchange de criptomonedas o un proveedor de venta libre (OTC) que admita las Stablecoins de FD121. Por favor, ten en cuenta todas las regulaciones legales y financieras en tu jurisdicción antes de proceder.',
        Explore_desc: "Explora nuestro Ecosistema",
        Experience_desc: 'Experimenta la estabilidad con nuestra solución de Stablecoin confiable.',
        slide_desc: 'diapositiva',
        _1to6_desc: '1 al 6',
        of7_desc: 'de 7',
        Transparency_desc: 'Transparencia',
        Legal_desc: 'Legal',
        Compliance_desc: 'Cumplimiento',
        Policy_desc: 'Política de Privacidad',
        Company_desc: 'Empresa',
        About_Us_desc: 'Sobre Nosotros',
        Social_Media_desc: 'Redes Sociales',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Divulgaciones Importantes:',
        Limited_desc: 'FD121 Limited es una compañía de tecnología financiera, no un banco. Los servicios de custodia de los activos están proporcionados por ',
        First_desc: "First Digital Trust Limited",
        product_desc: 'Todos los nombres de productos y compañías son marcas comerciales™ o marcas registradas® de sus respectivos titulares. Todos los nombres de compañías, productos y servicios utilizados en este sitio web son solo para fines de identificación. El uso de estos nombres, marcas comerciales y marcas no implica ninguna afiliación o respaldo por parte de ellos.',
        additional_desc: 'Para riesgos, divulgaciones e información adicional importante, visita',
        Copyright_desc: 'Derechos de Autor © 2024 FD121 Limited. Todos los Derechos Reservados.'
    },
    modal: {
        hold_desc: 'Si tienes',
        Smart_desc: 'de BNB Smart Chain, obtendrás una recompensa adicional,',
        Rewards_desc: 'Las recompensas solo están disponibles para cantidades superiores a',
        address_desc: 'La dirección tiene',
        hours_desc: 'durante 24 horas, y el mínimo de retención se basa en la captura aleatoria del sistema. El monto se asigna una vez cada 24 horas. La asignación real a la cuenta prevalecerá.'
    },
    UserInfo: {
        Wallets_desc: 'Carteras',
        Reward_desc: 'Recompensa',
        half_month: '15 días',
        one_month: '1 mes',
        three_month: '3 meses',
        Invitees: 'Invitados',
        no_data: 'No hay datos',
        walletNo_support: 'Tu billetera no es compatible. Por favor, usa otra aplicación de billetera.',
        network_wrong: 'Por favor, selecciona la red correcta',
      },
}
export default en_ES

// Datajson:{
//     language_json:{
//         zh_json:'Chino simplificado',
//         zh_FT_json: 'Chino tradicional',
//         eh_json:'Inglés',
//         ja_JP_json:'Japonés',  
//         ko_KR_json:'Coreano' ,
//         en_TH_json:'Tailandés',
//         fr_FR_json:'Francés',
//         ru_RU_json:'Ruso',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonesio',
//         en_BS_json:'Persa',
//         en_YN_json:'Vietnamita',
//         en_ALB_json:'árabe',
//         en_TEQ_json:'Turco',
//         en_ES_json:'Español',
//         en_PTY_json:'Portugués',
//         en_YDL_json:'Italianos'
//     },
// },