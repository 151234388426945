//简体中文
const zh_FT = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: '語言',
        fduse_desc: 'FDUSD是一種1:1與美元掛鉤的穩定幣.',
        introduce_desc: "以尖端數位穩定幣徹底改革全球金融，為全球企業和創造者提供安全、快速和創新的解決方案。",
        getfduse_desc: "獲取FDUSD",
        stability_desc: '穩定性與創新相結合',
        experience_desc: '體驗閃電般的快速速度和最低成本。 通過我們創新且值得信賴的解決方案塑造金融的未來。',
        whitepaper_desc: '白皮書',
        redeemable_desc: '可兌換為美元的1:1比例',
        dollars_desc: 'FDUSD旨在完全由現金和現金等價資產支持。 代幣可按1:1比例兌換為美元。',
        backed_desc: '由完全儲備的資產支持',
        deserved_desc: '儲備資產被完全隔離並儲存在遠離破產風險的持有結構中。',
        programmable_desc: "可編程描述",
        innovative_desc: "憑藉我們創新的穩定幣解決方案，通過先進的技術支持，我們旨在提供一種安全可靠的選擇，給人們帶來安心，並在金融領域產生積極的影響。",
        settle_desc: "結算描述",
        nearZero_desc: "接近零成本",
        always_desc: "始終在線，全天候運行",
        fully_desc: "完全可編程",
        rewrite_desc: "與第一數字實驗室一同改寫未來",
        combining_desc: "在第一數字實驗室中，將穩定性與創新相結合，我們致力於通過提供始終如一的透明度、合規性和安全性，以及先進的技術支持來贏得信任。",
        mission_desc: "我們的使命是推動金融行業進入金融新時代，並重塑全球金融格局。",
        should_desc: "為什麼你應該使用第一數字美元（FDUSD）呢？",
        collateral_desc: "抵押品由一家頂級、合格的托管機構進行保護，其擁有信託許可證，以實現資產的無縫保護和合規性。",
        transparent_desc: "透明",
        independent_desc: "獨立審計-抵押品由獨立的第三方進行驗證，確保儲備和財務報告的完整性。",
        examined_by_desc: "經過審查",
        attestation_Reports_desc: "審計報告",
        January2024_desc: "2024年1月份的審計報告",
        Report_desc: "報告",
        December_desc: "2023年12月份的報告",
        November_desc: "2023年11月份的報告",
        Networks_desc: "第一數字實驗室支持哪些網絡？",
        available_desc: "FDUSD可在以下網絡上使用",
        Ethereum_desc: "以太坊",
        and_desc: "和",
        BNB_Chain_desc: "BNB鏈",
        planned_desc: "計劃支持越來越多的區塊鏈。",
        Audited_desc: "經過審計",
        Frequently_desc: "常見問題",
        How_FDUSD_desc: "您如何獲取FDUSD？",
        Acquiring_desc: "獲得FDUSD可以通過幾種途徑實現。如果您是重要的行業參與者、金融中介機構或具有一定身份地位的專業投資者，並符合相應的條件，您可以直接從第一數字實驗室購買FDUSD。請注意，第一數字實驗室不直接向零售客戶銷售代幣。",
        canstill_desc: "您仍然可以通過二級市場獲得FDUSD，而無需成為第一數字實驗室的客戶。許多領先的加密貨幣交易所都會列出FDUSD，並提供可觀的流動性。這為有興趣的個人提供了獲取FDUSD的一種便捷方式。然而，始終要進行徹底的研究，並確保您使用的交易所的可信度。",
        equivalent_desc: "您如何相信FDUSD始終等同於一美元呢？",
        stablecoin_desc: "您對FDUSD穩定幣價值的信任是有根據的。每個FDUSD旨在完全由一美元或具有等值公正價值的資產支持。這些資產由合格的托管機構安全持有，存放在受監管的存管機構帳戶中，為該穩定幣的價值提供了堅實的基礎。正是這個系統賦予了FDUSD內在的穩定性，也是您可以相信其與美元等值的原因。",
        redeem_FDUSD: "您如何兌換FDUSD呢？",
        stablecoin_desc: "要兌換您的FDUSD穩定幣，您首先必須成為第一數字實驗室的客戶，並滿足特定要求，包括反洗錢（AML）和反恐融資（CTF）檢查。成功完成這些檢查後，您可以將FDUSD兌換為等值的法定貨幣，從而使其退出流通。另外，您還可以通過支持FD121穩定幣的加密貨幣交易所或場外交易（OTC）提供商，在二級市場上出售您的FDUSD代幣。在進行操作之前，請注意您所在地區的所有法律和金融監管規定。",
        Explore_desc: "探索我們的生態系統",
        Experience_desc: "通過我們可靠的穩定幣解決方案來體驗穩定性。",
        slide_desc: "滑動",
        _1to6_desc: "1到6",
        of7_desc: "第7",
        Transparency_desc: "透明度",
        Legal_desc: "合法的",
        Compliance_desc: "合規性",
        Policy_desc: "隱私政策",
        Company_desc: "公司",
        About_Us_desc: "關於我們",
        Social_Media_desc: "社交媒體",
        Twitter_desc: "推特",
        Disclosures_desc: "重要披露事項:",
        Limited_desc: "FD121有限公司是一家金融科技公司，而不是銀行。儲備資產的托管服務由提供",
        First_desc: "第一數碼信託有限公司",
        product_desc: "所有產品和公司名稱均為其各自持有人的商標™或註冊商標®。本網站中使用的所有公司、產品和服務名稱僅用於識別目的。使用這些名稱、商標和品牌並不意味著與它們有任何關聯或認可。",
        additional_desc: "為了獲取其他重要風險、披露和信息，請訪問。",
        Copyright_desc: "版權所有 © 2024 FD121有限公司。保留所有權利。"

    },
    modal: {
        hold_desc: "如果你擁有",
        Smart_desc: "在BNB智能鏈中，您將獲得獎金獎勵，",
        Rewards_desc: "只有超過一定金額的購買才能獲得獎勵。",
        address_desc: "該地址持有",
        hours_desc: "在24小時內持有，並且最低持有量基於系統的隨機快照。金額將每24小時進行分配。實際分配以賬戶為準。"
    }
    ,
    UserInfo: {
        Wallets_desc: "錢包",
        Reward_desc: "獎勵",
        half_month: "15天",
        one_month: "1個月",
        three_month: "3個月",
        Invitees: "被邀請",
        no_data: "什麼都沒有哦",
        walletNo_support: '您的钱包不受支持。请使用其他钱包应用程序。',
        network_wrong: "請選擇正確的網路",
    }
}
export default zh_FT

// Datajson: {
//     language_json: {
//         zh_json: '簡體中文',
//         zh_FT_json: '繁體中文',
//         eh_json: '英語',
//         ja_JP_json: '日語',
//         ko_KR_json: '韓語',
//         en_TH_json: '泰語',
//         fr_FR_json: '法語',
//         ru_RU_json: '俄語',
//         en_IN_json: '印地語',
//         en_ID_json: '印尼語',
//         en_BS_json: '波斯語',
//         en_YN_json: '越南語',
//         en_ALB_json: '阿拉伯語',
//         en_TEQ_json: '土耳其語',
//         en_ES_json: '西班牙語',
//         en_PTY_json: '葡萄牙語',
//         en_YDL_json: '義大利語'
//     },
// },