//连接钱包
/**
 * 
 * @param {*} params //钱包地址
 * @returns 
 */
async function  checkConnect(params) {
    let accounts;
    if (window.ethereum) {
        try {
            accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });//接钱包
            console.log('链接钱包11111', accounts)
            window.ethereum.on('accountsChanged', (newAccounts) => { //以太坊账户发生切换
                console.log(newAccounts)
                if (newAccounts.length === 0) {
                    localStorage.removeItem("address_" + params.project_key);
                    sessionStorage.removeItem('current_address');
                } else {
                    sessionStorage.setItem('current_address', newAccounts[0]);
                    console.log('Accounts changed:', newAccounts);
                }
                this.checkAddress(newAccounts[0]);
            });
        } catch (e) {
            this.$message.error('wallet connect deny');
        }
    } else if (window.web3) {
        //Old version Metamask
        this.$message.error('old wallet version');
    } else {
        //Trust Wallet
    }
    //callback the address if it's gotten;
    if (accounts != null && accounts.length > 0) {
        sessionStorage.setItem('current_address', accounts[0]);
        return accounts[0];
    } else {
        console.log('wallet_connect_deny');
        this.$message.error('web3.wallet_connect_deny');
        return;
    }
}
//监听链id
/**
 * 
 * @param {*} params //Object -- chain_id
 * @returns 
 */
//网络id
async function checkChain(params) {
    // console.log('开始链接')
    // console.log('000000000', window.ethereum)
    if (window.ethereum) {
      console.log('Check chain connecting ');
      try {
        let network = await window.ethereum.request({ method: 'eth_chainId' });//获得以太网链ID
        network = parseInt(network, 16);
        console.log('上链获得的东西',network)
        sessionStorage.setItem('current_chainID', network);
        window.ethereum.on('chainChanged', (ChainId) => { //监听链ID
          console.log('Chain changed:', ChainId);
          ChainId = parseInt(ChainId, 16);
          sessionStorage.setItem('current_chainID', ChainId);
        //   if (ChainId !== params.chain_id) {
        //     console.log('chain id must is:', params.chain_id);
        //     //Alert change network
        //     this.$message.error('network error');
        //   }
        });
        // console.log("校验网络", network, params.chain_id);
        // if (network !== params.chain_id) {
        //   return false;
        // }
        //Listening network change
        return network;
      } catch (e) {
        return false;
      }
    }
}
/**
 * @param {*} params //授权交易
 */

export {checkConnect,checkChain}