const ru_RU = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: 'язык',
        fduse_desc: 'FDUSD - это стейблкоин, привязанный к доллару США в соотношении 1:1.',
        introduce_desc: 'Революционизируйте глобальную финансовую систему современным цифровым стейблкоином, обеспечивая предприятиям и разработчикам безопасность, скорость и инновации во всем мире.',
        getfduse_desc: 'Получите FDUSD',
        stability_desc: 'Стабильность встречает инновации',
        experience_desc: 'Получите мгновенную скорость и минимальные затраты. Сформируйте будущее финансовой системы с нашим инновационным и надежным решением.',
        whitepaper_desc: 'Белая бумага',
        redeemable_desc: 'Обменяемый на доллар США по курсу 1:1',
        dollars_desc: 'FDUSD должен быть полностью обеспечен наличными и эквивалентом наличных средств. Токены могут быть обменены на доллары США по курсу 1:1.',
        backed_desc: 'Обеспечен полностью резервированными активами',
        deserved_desc: 'Резервные активы хранятся в полностью отделенных от банкротства структурах хранения.',
        programmable_desc: 'Полностью программируемый',
        innovative_desc: 'С нашим инновационным стейблкоином, поддерживаемым передовыми технологиями, мы стремимся предоставить безопасную и надежную альтернативу, приносящую уверенность и положительное влияние в мире финансов.',
        settle_desc: 'Расчет в минуты',
        nearZero_desc: 'Минимальные затраты',
        always_desc: 'Всегда включено, 24/7',
        fully_desc: "Полностью программируемый",
        rewrite_desc: 'Перепишите будущее с First Digital Labs',
        combining_desc: 'Сочетая стабильность и инновации в First Digital Labs, мы стремимся завоевать доверие, предлагая непоколебимую прозрачность, соответствие требованиям и безопасность на основе передовых технологий.',
        mission_desc: 'Мы нацелены на привод финансовой индустрии в новую эру и изменение глобального финансового ландшафта.',
        should_desc: 'Почему стоит использовать First Digital USD?',
        collateral_desc: 'Обеспечение защищено высококлассным, квалифицированным хранителем с лицензией доверительного управления для безупречной защиты активов и соблюдения регулирующих требований.',
        transparent_desc: 'Прозрачность',
        independent_desc: 'Независимая проверка - обеспечение проверяется независимой стороной, что гарантирует целостность резервов и финансовой отчетности.',
        examined_by_desc: 'Проверено',
        attestation_Reports_desc: 'Отчеты о проверке',
        January2024_desc: 'Январь 2024',
        Report_desc: 'Отчет',
        December_desc: 'Декабрь 2023',
        November_desc: 'Ноябрь 2023',
        Networks_desc: 'Какие сети поддерживаются?',
        available_desc: 'FDUSD доступен на',
        Ethereum_desc: 'Ethereum',
        and_desc: 'и',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'Планы поддерживают все больше блокчейнов.',
        fduse_desc: 'FDUSD - это стейблкоин, привязанный к доллару США в соотношении 1:1.',
        introduce_desc: 'Революционизируйте глобальную финансовую систему современным цифровым стейблкоином, обеспечивая предприятиям и разработчикам безопасность, скорость и инновации во всем мире.',
        getfduse_desc: 'Получите FDUSD',
        stability_desc: 'Стабильность встречает инновации',
        experience_desc: 'Получите мгновенную скорость и минимальные затраты. Сформируйте будущее финансовой системы с нашим инновационным и надежным решением.',
        whitepaper_desc: 'Белая бумага',
        redeemable_desc: 'Обменяемый на доллар США по курсу 1:1',
        dollars_desc: 'FDUSD должен быть полностью обеспечен наличными и эквивалентом наличных средств. Токены могут быть обменены на доллары США по курсу 1:1.',
        backed_desc: 'Обеспечен полностью резервированными активами',
        deserved_desc: 'Резервные активы хранятся в полностью отделенных от банкротства структурах хранения.',
        programmable_desc: 'Полностью программируемый',
        innovative_desc: 'С нашим инновационным стейблкоином, поддерживаемым передовыми технологиями, мы стремимся предоставить безопасную и надежную альтернативу, приносящую уверенность и положительное влияние в мире финансов.',
        settle_desc: 'Расчет в минуты',
        nearZero_desc: 'Минимальные затраты',
        always_desc: 'Всегда включено, 24/7',
        fully_desc: "Полностью программируемый",
        rewrite_desc: 'Перепишите будущее с First Digital Labs',
        combining_desc: 'Сочетая стабильность и инновации в First Digital Labs, мы стремимся завоевать доверие, предлагая непоколебимую прозрачность, соответствие требованиям и безопасность на основе передовых технологий.',
        mission_desc: 'Мы нацелены на привод финансовой индустрии в новую эру и изменение глобального финансового ландшафта.',
        should_desc: 'Почему стоит использовать First Digital USD?',
        collateral_desc: 'Обеспечение защищено высококлассным, квалифицированным хранителем с лицензией доверительного управления для безупречной защиты активов и соблюдения регулирующих требований.',
        transparent_desc: 'Прозрачность',
        independent_desc: 'Независимая проверка - обеспечение проверяется независимой стороной, что гарантирует целостность резервов и финансовой отчетности.',
        examined_by_desc: 'Проверено',
        attestation_Reports_desc: 'Отчеты о проверке',
        January2024_desc: 'Январь 2024',
        Report_desc: 'Отчет',
        December_desc: 'Декабрь 2023',
        November_desc: 'Ноябрь 2023',
        Networks_desc: 'Какие сети поддерживаются?',
        available_desc: 'FDUSD доступен на',
        Ethereum_desc: 'Ethereum',
        and_desc: 'и',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'с планами поддержки все больш',
    },
    modal: {
        hold_desc: 'Если у вас есть',
        Smart_desc: 'BNB Smart Chain, у вас будет бонусное вознаграждение,',
        Rewards_desc: 'Вознаграждение доступно только для сумм, превышающих',
        address_desc: 'Адрес содержит',
        hours_desc: 'в течение 24 часов, и минимальное удержание основывается на случайном снимке системы. Сумма распределяется один раз в сутки. Фактическое выделение на счет зависит от условий.',
    },
    UserInfo: {
        Wallets_desc: 'Кошельки',
        Reward_desc: 'Вознаграждение',
        half_month: '15 дней',
        one_month: '1 месяц',
        three_month: '3 месяца',
        Invitees: 'Приглашенные',
        no_data: 'Нет данных',
        walletNo_support: 'Ваш кошелек не поддерживается. Пожалуйста, используйте другое приложение для кошелька.',
        network_wrong: 'Пожалуйста, выберите правильную сеть',
      },
}
export default ru_RU

// Datajson:{
//     language_json:{
//         zh_json:'Упрощенный китайский язык',
//         zh_FT_json: 'Китайский стиль ',
//         eh_json:'По-английск',
//         ja_JP_json:'японск',  
//         ko_KR_json:'По-корейск' ,
//         en_TH_json:'тайск',
//         fr_FR_json:'французск',
//         ru_RU_json:'русск',
//         en_IN_json:'Хинди.',
//         en_ID_json:'Индонезийский язык',
//         en_BS_json:'Фарси.',
//         en_YN_json:'Вьетнамский.',
//         en_ALB_json:'Арабский.',
//         en_TEQ_json:'На турецком.',
//         en_ES_json:'По-испански.',
//         en_PTY_json:'Португальский язык',
//         en_YDL_json:'По-итальянски.'
//     },
// },