//简体中文
const zh = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: '语言',
        fduse_desc: 'FDUSD是一种1:1与美元挂钩的稳定币.',
        introduce_desc: "以尖端数字稳定币彻底改革全球金融，为全球企业和创造者提供安全、快速和创新的解决方案。",
        getfduse_desc: "获取FDUSD",
        stability_desc: '稳定性与创新相结合',
        experience_desc: '体验闪电般的快速速度和最低成本。通过我们创新且值得信赖的解决方案塑造金融的未来。',
        whitepaper_desc: '白皮书',
        redeemable_desc: '可兑换为美元的1:1比例',
        dollars_desc: 'FDUSD旨在完全由现金和现金等价资产支持。代币可按1:1比例兑换为美元。',
        backed_desc: '由完全储备的资产支持',
        deserved_desc: '储备资产被完全隔离并储存在远离破产风险的持有结构中。',
        programmable_desc: '可编程描述',
        innovative_desc: '凭借我们创新的稳定币解决方案，通过先进的技术支持，我们旨在提供一种安全可靠的选择，给人们带来安心，并在金融领域产生积极的影响。',
        settle_desc: '结算描述',
        nearZero_desc: '接近零成本',
        always_desc: '始终在线，全天候运行',
        fully_desc: '完全可编程',
        rewrite_desc: '与第一数字实验室一同改写未来',
        combining_desc: '在第一数字实验室中，将稳定性与创新相结合，我们致力于通过提供始终如一的透明度、合规性和安全性，以及先进的技术支持来赢得信任。',
        mission_desc: '我们的使命是推动金融行业进入金融新时代，并重塑全球金融格局。',
        should_desc: '为什么你应该使用第一数字美元（FDUSD）呢？',
        collateral_desc: '抵押品由一家顶级、合格的托管机构进行保护，其拥有信托许可证，以实现资产的无缝保护和合规性。',
        transparent_desc: '透明',
        independent_desc: '独立审计-抵押品由独立的第三方进行验证，确保储备和财务报告的完整性。',
        examined_by_desc: '经过审查',
        attestation_Reports_desc: '审计报告',
        January2024_desc: '2024年1月份的审计报告',
        Report_desc: '报告',
        December_desc: '2023年12月份的报告',
        November_desc: '2023年11月份的报告',
        Networks_desc: '第一数字实验室支持哪些网络？',
        available_desc: 'FDUSD可在以下网络上使用',
        Ethereum_desc: '以太坊',
        and_desc: '和',
        BNB_Chain_desc: 'BNB链',
        planned_desc: '计划支持越来越多的区块链。',
        Audited_desc: '经过审计',
        Frequently_desc: '常见问题',
        How_FDUSD_desc: '您如何获取FDUSD？',
        Acquiring_desc: '获得FDUSD可以通过几种途径实现。如果您是重要的行业参与者、金融中介机构或具有一定身份地位的专业投资者，并符合相应的条件，您可以直接从第一数字实验室购买FDUSD。请注意，第一数字实验室不直接向零售客户销售代币。',
        canstill_desc: '您仍然可以通过二级市场获得FDUSD，而无需成为第一数字实验室的客户。许多领先的加密货币交易所都会列出FDUSD，并提供可观的流动性。这为有兴趣的个人提供了获取FDUSD的一种便捷方式。然而，始终要进行彻底的研究，并确保您使用的交易所的可信度。',
        equivalent_desc: '您如何相信FDUSD始终等同于一美元呢？',
        stablecoin_desc: '您对FDUSD稳定币价值的信任是有根据的。每个FDUSD旨在完全由一美元或具有等值公正价值的资产支持。这些资产由合格的托管机构安全持有，存放在受监管的存管机构账户中，为该稳定币的价值提供了坚实的基础。正是这个系统赋予了FDUSD内在的稳定性，也是您可以相信其与美元等值的原因。',
        redeem_FDUSD: '您如何兑换FDUSD呢？',
        stablecoin_desc: '要兑换您的FDUSD稳定币，您首先必须成为第一数字实验室的客户，并满足特定要求，包括反洗钱（AML）和反恐融资（CTF）检查。成功完成这些检查后，您可以将FDUSD兑换为等值的法定货币，从而使其退出流通。另外，您还可以通过支持FD121稳定币的加密货币交易所或场外交易（OTC）提供商，在二级市场上出售您的FDUSD代币。在进行操作之前，请注意您所在地区的所有法律和金融监管规定。',
        Explore_desc: '探索我们的生态系统',
        Experience_desc: '通过我们可靠的稳定币解决方案来体验稳定性。',
        slide_desc: '滑动',
        _1to6_desc: "1到6",
        of7_desc: '第7',
        Transparency_desc: '透明度',
        Legal_desc: '合法的',
        Compliance_desc: '合规性',
        Policy_desc: '隐私政策',
        Company_desc: '公司',
        About_Us_desc: '关于我们',
        Social_Media_desc: '社交媒体',
        Twitter_desc: '推特',
        Disclosures_desc: '重要披露事项:',
        Limited_desc: 'FD121有限公司是一家金融科技公司，而不是银行。储备资产的托管服务由提供',
        First_desc: '第一数码信托有限公司',
        product_desc: '所有产品和公司名称均为其各自持有人的商标™或注册商标®。本网站中使用的所有公司、产品和服务名称仅用于识别目的。使用这些名称、商标和品牌并不意味着与它们有任何关联或认可。',
        additional_desc: '为了获取其他重要风险、披露和信息，请访问。',
        Copyright_desc: '版权所有 © 2024 FD121有限公司。保留所有权利。'

    },
    modal: {
        hold_desc: '如果你拥有',
        Smart_desc: '在BNB智能链中， 您将获得奖金奖励，',
        Rewards_desc: '只有超过一定金额的购买才能获得奖励。',
        address_desc: '该地址持有',
        hours_desc: '在24小时内持有，并且最低持有量基于系统的随机快照。金额将每24小时进行分配。实际分配以账户为准。',
        copyLink_desc: '复制链接'
    },
    UserInfo: {
        Wallets_desc: "钱包",
        Reward_desc: "奖励",
        half_month: "15天",
        one_month: "1个月",
        three_month: "3个月",
        Invitees:'被邀请',
        no_data: "什么都没有哦",
        walletNo_support:'你的钱包不支持,请用其它钱包app',
        network_wrong: "请选择正确的网络",
    }

}
export default zh

// Datajson:{
//     language_json:{
//         zh_json:'简体中文',
//         zh_FT_json: '繁体中文',
//         eh_json:'英语',
//         ja_JP_json:'日语',
//         ko_KR_json:'韩语' ,
//         en_TH_json:'泰语',
//         fr_FR_json:'法语',
//         ru_RU_json:'俄语',
//         en_IN_json:'印地语',
//         en_ID_json:'印度尼西亚语',
//         en_BS_json:'波斯语',
//         en_YN_json:'越南语',
//         en_ALB_json:'阿拉伯语',
//         en_TEQ_json:'土耳其语',
//         en_ES_json:'西班牙语',
//         en_PTY_json:'葡萄牙语',
//         en_YDL_json:'意大利语'
//     },
// },