const en_BS = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'زبان',
        fduse_desc: 'FDUSD یک Stablecoin با پشتوانه یک به یک دلار آمریکا است.',
        introduce_desc: 'با استفاده از یک استیبل کوین دیجیتال پیشرفته، ما در حال آشوب زدایی در مالی جهانی هستیم و برای شرکت‌ها و سازندگان سرعت و نوآوری را در سراسر جهان فراهم می‌کنیم.',
        getfduse_desc: 'FDUSD دریافت کنید',
        stability_desc: 'استحکام با نوآوری همراه است',
        experience_desc: 'سرعت بسیار بالا و هزینه‌های کم. با راهکار نوآورانه و قابل اعتماد خود، آینده مالی را شکل دهید.',
        whitepaper_desc: 'کاغذ سفید',
        redeemable_desc: 'امکان تبدیل یک به یک به دلار آمریکا',
        dollars_desc: 'FDUSD به طور کامل بر اساس پشتوانه نقدی و دارایی‌های معادل نقدی است. توکن‌ها قابل تبدیل یک به یک به دلار آمریکا هستند.',
        backed_desc: 'با پشتوانه دارایی‌های کامل',
        deserved_desc: 'دارایی‌های پشتوانه در ساختارهای نگهداری مستقل و دور از ورشکستگی نگهداری می‌شوند.',
        programmable_desc: 'کاملا قابل برنامه‌ریزی',
        innovative_desc: 'با استفاده از راه‌حل استیبل کوین نوآورانه ما که با فناوری پیشرفته پشتوانه‌گذاری شده است، هدف ما ارائه یک جایگزین امن و قابل اعتماد است که آرامش را به همراه دارد و در جهان مالی تأثیر مثبتی می‌گذارد.',
        settle_desc: 'در عرض چند دقیقه تسویه شوید',
        nearZero_desc: 'هزینه نزدیک به صفر',
        always_desc: 'همیشه فعال، ۲۴/۷',
        fully_desc: "کاملا قابل برنامه‌ریزی",
        rewrite_desc: 'نویسی آینده با First Digital Labs',
        combining_desc: 'ترکیب استحکام با نوآوری در First Digital Labs، ما متعهد به بهبود اعتماد از طریق ارائه شفافیت، پایبندی به قوانین و امنیت مدرن به وسیله فناوری پیشرفته هستیم.',
        mission_desc: 'ما در یک مأموریت هستیم تا صنعت مالی را به دوران جدیدی از مالی پیشبرد کنیم و منظره مالی جهانی را تغییر دهیم.',
        should_desc: 'چرا باید از First Digital USD استفاده کنید؟',
        collateral_desc: 'تضمینات بازپرداخت توسط یک نگهدارنده برتر و صلاحیت‌دار با مجوز اعتماد برای حفاظت سریعاز دارایی‌ها تضمین می‌شود و با رعایت قوانین و مقررات کنترل می‌شود.',
        transparent_desc: 'شفاف',
        independent_desc: 'تایید مستقل - تضمینات توسط یک شخص ثالث مستقل تأیید می‌شود تا سلامت محفظه‌ها و گزارش‌های مالی تأیید شود.',
        examined_by_desc: 'تحت بازرسی',
        attestation_Reports_desc: 'گزارش‌های تأیید',
        January2024_desc: 'ژانویه ۲۰۲۴',
        Report_desc: 'گزارش',
        December_desc: 'دسامبر ۲۰۲۳',
        November_desc: 'نوامبر ۲۰۲۳',
        Networks_desc: 'کدام شبکه‌ها پشتیبانی می‌شوند؟',
        available_desc: 'FDUSD در دسترس است در',
        Ethereum_desc: 'Ethereum',
        and_desc: 'و',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'با پشتیبانی برای تعدادی از بلاکچین‌ها در دست بررسی.',
        Audited_desc: 'مورد بازرسی قرار گرفته است توسط',
        Frequently_desc: 'سوالات متداول',
        How_FDUSD_desc: 'چگونه می‌توانم FDUSD دریافت کنم؟',
        Acquiring_desc: 'می‌توان به دو روش FDUSD را دریافت کرد. اگر یک بازیگر کلیدی در صنعت، یک واسطه‌گر مالی یا یک سرمایه‌گذار حرفه‌ای باشید و شرایط مورد نیاز را داشته باشید، می‌توانید مستقیماً FDUSD را از First Digital Labs خریداری کنید. لطفاً توجه داشته باشید که First Digital Labs توکن‌ها را به صورت مستقیم به مشتریان خرده‌فروشی نمی‌فروشد.:',
        canstill_desc: 'هنوز می‌توانید FDUSD را از طریق بازارهای ثانویه بدست آورید بدون اینکه مشتری First Digital Labs شوید. بسیاری از صرافی‌های برتر رمزارز FDUSD را لیست می‌کنند و پایداری قابل توجهی را ارائه می‌دهند. این یک راه دسترسی آسان و مناسب برای افراد علاقه‌مند به دریافت FDUSD است. با این حال، همیشه ضروری است تحقیقات دقیقی انجام دهید و معتبر بودن صرافی که استفاده می‌کنید را تأیید کنید.',
        equivalent_desc: 'چگونه می‌توانم اعتماد کنم که FDUSD همیشه معادل یک دلار خواهد بود؟',
        stablecoin_desc: 'اعتماد شما به ارزش استیبل کوین FDUSD مبنایی قوی دارد. هر FDUSD قرار است به طور کامل توسط یک دلار آمریکا یا یک دارایی با ارزش عادلانه معادل پشتوانه گ',
    },
    modal: {
        hold_desc: 'اگر شما مقداری از BNB Smart Chain را نگه دارید، شما یک پاداش اضافی خواهید داشت.',
        Smart_desc: 'پاداش‌ها تنها برای مقادیری بیشتر از',
        Rewards_desc: 'موجود است.',
        address_desc: 'آدرس',
        hours_desc: '24 ساعت نگه‌داری می‌کند و حداقل نگهداری بر اساس لحظه‌ای تصادفی از سیستم است. مقدار هر 24 ساعت یکبار تخصیص داده می‌شود. تخصیص واقعی به حساب اعمال می‌شود.'
    },
    UserInfo: {
        Wallets_desc: 'کیف پول',
        Reward_desc: 'پاداش',
        half_month: '۱۵ روز',
        one_month: '۱ ماه',
        three_month: '۳ ماه',
        Invitees: 'مهمانان',
        no_data: 'هیچ داده‌ای وجود ندارد',
        walletNo_support: 'کیف پول شما پشتیبانی نمی‌شود. لطفاً از برنامه کیف پول دیگری استفاده کنید.',
        network_wrong: 'لطفاً شبکه صحیح را انتخاب کنید',
      },
}
export default en_BS
// Datajson:{
//     language_json:{
//         zh_json:'چینی ساده',
//         zh_FT_json: 'چینی سنتی',
//         eh_json:'انگلیسی',
//         ja_JP_json:'ژاپنی',  
//         ko_KR_json:'کوریه' ,
//         en_TH_json:'یName',
//         fr_FR_json:'فرانسوی',
//         ru_RU_json:'روسی',
//         en_IN_json:'هندي',
//         en_ID_json:'IndonesiaName',
//         en_BS_json:'فارسی',
//         en_YN_json:'ویتنامیName',
//         en_ALB_json:'عربی',
//         en_TEQ_json:'ترکیName',
//         en_ES_json:'اسپانیایName',
//         en_PTY_json:'پرتغالی',
//         en_YDL_json:'ایتالیایName'
//     },
// },