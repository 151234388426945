<template>
  <div class="frame-module">
    <ul-modal :isShow="true" @close="close">
      <template #header>
        <div style="margin-top: 20px">
          <el-radio-group v-model="amount" size="small">
            <el-radio-button label="100"></el-radio-button>
            <el-radio-button label="200"></el-radio-button>
            <el-radio-button label="300"></el-radio-button>
            <el-radio-button label="400"></el-radio-button>
          </el-radio-group>
        </div>
      </template>
      <template #main>
        <div class="ul-modal-mainbox">
          <div class="ul-modal-input">
            <el-input
              placeholder="Input pledged FDUSD"
              v-model="amount"
            ></el-input>
          </div>
          <div class="ul-modal-bnt">
            <el-button type="success" @click="Recharge" round>submit</el-button>
          </div>
        </div>
      </template>
    </ul-modal>
  </div>
</template>
<script>
import Web3 from "web3";
import UlModal from "@/components/Modal/UlModal.vue";

export default {
  components: {
    UlModal,
  },
  data() {
    return {
      project_key: this.$store.state.staking.project_key,
      amount: "",
      address: "",
      network: null,
    };
  },
  async created() {
    // this.StakingProjectConnector()
    console.log();
    if (window.ethereum.isMetaMask) {
      this.$message.error(this.$t("UserInfo.walletNo_support"));
      return;
    }
    const connecting_address = await this.checkConnect(); //连接钱包
    this.address = connecting_address;
    const network = await this.checkChain(); //检查网络
    this.network = network;
    console.log(network);
    //    await this.StakingProjectConnector()
    await this.signTransaction();
    // this.tokenContract()
  },
  methods: {
    async StakingProjectConnector() {
      // const fullLoading = this.$loading({
      //     lock: true,
      //     text: 'Loading project information',
      //     background: 'rgba(0, 0, 0, 0.7)',
      // });
      // fullLoading.close();
      const ConnectorParam = {
        project_key: this.project_key,
      };
      //初始信息 abi等等
      StakingProjectConnector(ConnectorParam).then(async (res) => {
        const connecting_address = await this.checkConnect(); //连接钱包

        const checkChain = await this.checkChain(); //检查网络

        if (connecting_address == null) {
          this.$message.error("Please connect your address");
          return;
        }
        this.address = connecting_address;
        if (!checkChain) {
          this.$message.error("Chain error");
          return;
        }
        return;
        const cheekParam = {
          connect_address: res.connect_address,
          address: connecting_address,
          project_key: this.project_key,
        };
        //查看账号状态 0未参与 1已参与 2参与又取消 3已拉黑 4已提现
        CheekStakingAddress(cheekParam).then((erss) => {
          if (erss.status == 1 || erss.status == 4) {
            //查看详情
            StakingDetail().then((detail) => {
              //发起交易
              this.tokenContract().then((val) => {
                //交易签名
                //签名成功 param
                Stake().then((tak) => {});
              });
            });
          } else {
            //参与
            StakingStart().then((start) => {});
          }
        });
      });

      // const txObject = {
      //     nonce: web3.utils.toHex(nonce), // 交易的nonce，表示发送账户的交易次数
      //     gasLimit: web3.utils.toHex(gasLimit), // 交易的gas限制
      //     gasPrice: web3.utils.toHex(gasPrice), // 交易的gas价格
      //     to: recipientAddress, // 接收方的以太坊地址
      //     value: web3.utils.toHex(value), // 转账的以太币数量
      //     data: txData, // 如果是调用合约方法，可以传递合约方法的数据
      //     chainId: web3.utils.toHex(chainId), // 链的ID，用于签名
      // };
      // const signedTx = await web3.eth.accounts.signTransaction(txObject, privateKey);
      //             const transaction = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
      // console.log('Transaction hash:', transaction.transactionHash);
    },
    //交易授权
    tokenContract(abi, connect_address) {
      return new Promise(async (resolve, reject) => {
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        let web3 = new Web3(window.ethereum);
        console.log("wb3", accounts);
        const tokenContract = new web3.eth.Contract(
          JSON.parse(
            localStorage.getItem("abi_" + this.project_key).toString()
          ),
          localStorage.getItem("contract_addr_" + this.project_key)
        ); //创建智能合约实例
        let hashVal;
        let spender = accounts[0];
        let approveNum = "1000000000000000000";

        tokenContract.methods
          .increaseAllowance(spender, approveNum)
          .send({
            from: accounts[0], // 发送交易的地址
          })
          .on("transactionHash", (hash) => {
            hashVal = hash;
            console.log("Transaction hash:", hash);
          })
          .on("receipt", (receipt) => {
            console.log("Receipt:", receipt);
            resolve(receipt);
            // 交易成功后的处理逻辑
          })
          .on("error", (error) => {
            console.error("Error:", error);
            reject(error);
            // 处理错误情况
          })
          .catch((error) => {
            console.log("已经拒绝");
            reject(error);
          });
      });
    },
    //交易签名
    async signTransaction() {
      let web3 = new Web3(window.ethereum);
      const transactionObject = {
        from: this.address,
        to: "0x61b935f26228ffa05d2d44e7809233aff2732510",
        value: web3.utils.toWei("1", "ether"),
        gas: 21000,
        gasPrice: web3.utils.toWei("10", "gwei"),
        nonce: 0,
        chainId: this.network, // Ropsten 测试网络的链ID
      };

      // 使用私钥对交易进行签名
      const privateKey =
        "b957d3793b403573dea3403e90fd7356718477560f27c1ebcf969a541da1606a";

      // web3.eth.sendTransaction(transactionObject)
      const signedTx = await web3.eth.accounts
        .signTransaction(transactionObject, privateKey)
        .then((signedTx) => {
          const serializedTx = signedTx.rawTransaction;
          console.log("Signed transaction:", serializedTx);
          return signedTx;
          // 在这里可以将已签名的交易广播到网络上
        })
        .catch((error) => {
          console.error("Transaction signing error:", error);
          // 在这里处理错误
        });
      console.log("签名", signedTx.rawTransaction);
      const transaction = await web3.eth.sendSignedTransaction(
        signedTx.rawTransaction
      );
    },
    Recharge() {},
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style-scss/common.scss";

.ul-modal-mainbox {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
}

.ul-modal-input {
}

.ul-modal-bnt {
  margin-top: 3rem;
}
</style>
