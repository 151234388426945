import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n-vue/index'
import ElementUI from "element-ui";
import 'wowjs/css/libs/animate.css'
import 'element-ui/lib/theme-chalk/index.css';
import '@/mixin/index'
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$websiteUrl = 'https://firstdigitallabs.com/';
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
