//韩语
const ko_KR = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: '언어',
        fduse_desc: 'FDUSD는 1:1로 달러에 지원되는 안정적인 암호화폐입니다.',
        introduce_desc: '혁신적인 디지털 안정화폐를 통해 글로벌 금융을 혁신하고, 보안, 속도 및 혁신을 제공하여 전 세계의 기업과 건설자들에게 혁신적인 솔루션을 제공합니다.',
        getfduse_desc: 'FDUSD 받기',
        stability_desc: '안정성과 혁신의 만남',
        experience_desc: '초고속 거래속도와 최소한의 비용을 경험해 보세요. 혁신적이고 신뢰할 수 있는 솔루션으로 금융의 미래를 함께 만들어 보세요.',
        whitepaper_desc: '화이트페이퍼',
        redeemable_desc: '1:1로 미국 달러로 교환 가능',
        dollars_desc: 'FDUSD는 현금 및 현금 등가 자산으로 완전히 지원될 것으로 예상됩니다. 토큰은 1:1로 미국 달러로 교환 가능하도록 되어 있습니다.',
        backed_desc: '완전히 예약된 자산으로 지원됨',
        deserved_desc: '예약된 자산은 완전히 분리된 파산 방지 보유 구조에 보관됩니다.',
        programmable_desc: '완전히 프로그래밍 가능',
        innovative_desc: '진보적인 기술로 지원되는 혁신적인 안정화폐 솔루션으로, 안심과 신뢰를 제공하여 금융 분야에 긍정적인 영향을 주고자 합니다.',
        settle_desc: '몇 분 안에 체결',
        nearZero_desc: '거의 없는 비용',
        always_desc: '항상 켜져 있음, 24시간 7일',
        fully_desc: "완전히 프로그래밍 가능",
        rewrite_desc: 'First Digital Labs와 함께 미래를 새롭게 쓰다',
        combining_desc: 'First Digital Labs에서 안정성과 혁신을 결합하여, 우리는 철저한 투명성, 준수 및 최첨단 기술로 지원되는 신뢰를 얻기 위해 노력하고 있습니다.',
        mission_desc: '금융 산업을 새로운 금융 시대로 이끌고 글로벌 금융 풍토를 재편하고자 합니다.',
        should_desc: '왜 First Digital USD를 사용해야 할까요?',
        collateral_desc: '담보물은 최고 수준의 자격을 갖춘 보관인이 신뢰라이선스로 보호합니다.',
        transparent_desc: '투명성',
        independent_desc: '동일한 제 3자에 의해 담보물이 검증되어 예비 자금 및 재무 보고서의 무결성이 보장됩니다.',
        examined_by_desc: '검토자',
        attestation_Reports_desc: '확인 보고서',
        January2024_desc: '2024년 1월',
        Report_desc: '보고서',
        December_desc: '2023년 12월',
        November_desc: '2023년 11월',
        Networks_desc: '지원되는 네트워크는 어떤 것이 있나요?',
        available_desc: 'FDUSD는 다음에서 사용 가능합니다.',
        Ethereum_desc: '이더리움',
        and_desc: '그리고',
        BNB_Chain_desc: 'BNB 체인',
        planned_desc: '더 많은 블록체인을 지원할 예정입니다.',
        Audited_desc: '검사자는',
        Frequently_desc: '자주 묻는 질문',
        How_FDUSD_desc: '어떻게 FDUSD를 얻을 수 있을까요?',
        Acquiring_desc: 'FDUSD를 얻는 방법은 몇 가지가 있습니다. 주요 산업 참가자, 금융 중개인 또는 특정 지위의 전문 투자자인 경우 First Digital Labs에서 직접 FDUSD를 구매할 수 있습니다. First Digital Labs는 소매 고객에게 토큰을 직접 판매하지 않습니다.:',
        canstill_desc: 'First Digital Labs의 고객이 되지 않고도 보조 시장을 통해 여전히 FDUSD를 얻을 수 있습니다. 많은 주요 암호화폐 거래소에서 FDUSD를 상장하고 상당한 유동성을 제공합니다. 이는 관심 있는 개인들이 FDUSD를 얻기 위한 접근 가능하고 편리한 방법을 제공합니다. 그러나 사용 중인 거래소의 신뢰성을 확실히 조사하고 확인하는 것이 항상 중요합니다.',
        equivalent_desc: 'FDUSD가 항상 달러와 동일한 가치를 갖는 것에 대해 어떻게 신뢰할 수 있을까요?',
        stablecoin_desc: 'FDUSD의 안정적인 가치에 대한 신뢰는 충분히 근거가 있습니다. 각 FDUSD는 미국 달러 하나 또는 동등한 공정 가치의 자산으로 완전히 지원될 것으로 예상됩니다. 이러한 자산은 신뢰할 수 있는 보관인이 규제된 예금 기관의 계정에서 안전하게 보관되어 코인의 가치에 견고한 기반이 마련됩니다. 이 시스템은 FDUSD에 고유한 안정성을 제공하고 미국 달러와 동등한 가치에 대한 신뢰를 보장하는 이유입니다.',
        redeem_FDUSD: 'FDUSD를 어떻게 교환할 수 있을까요?',
        stablecoin_desc: 'FDUSD 안정화폐를 교환하려면 먼저 First Digital Labs의 고객이 되어야 하며, 특정 요구 사항을 충족해야 합니다. 이에는 미래자금세탁방지(AML) 및 테러자금조달(CTF) 점검을 포함합니다. 이러한 점검을 성공적으로 완료한 후에는 FDUSD를 현금과 동등한 가치로 환전하여 순환에서 제외할 수 있습니다. 또는 암호화폐 거래소나 OTC 공급자에서 FD121의 안정화폐를 지원하는 보조 시장에서 FDUSD 토큰을 판매할 수도 있습니다. 진행하기 전에 귀하의 관할권에서의 모든 법적 및 금융 규정을 숙지해야 합니다.',
        Explore_desc: "생태계 탐색",
        Experience_desc: '신뢰할 수 있는 안정화폐 솔루션으로 안정성을 경험해 보세요.',
        slide_desc: '슬라이드',
        _1to6_desc: '1에서 6까지',
        of7_desc: '7 중',
        Transparency_desc: '투명성',
        Legal_desc: '법적 사항',
        Compliance_desc: '준수',
        Policy_desc: '개인정보 처리방침',
        Company_desc: '회사',
        About_Us_desc: '회사 소개',
        Social_Media_desc: '소셜 미디어',
        Twitter_desc: '트위터',
        Disclosures_desc: '중요',
    },
    modal: {
        hold_desc: '보유하고 있다면',
        Smart_desc: 'BNB Smart Chain의 경우 보너스 보상을 받을 수 있습니다.',
        Rewards_desc: '보상은',
        address_desc: '주소는',
        hours_desc: '24시간 동안 유지되며, 최소 보유량은 시스템의 무작위 스냅샷을 기준으로 합니다. 실제 할당은 매 24시간마다 이루어집니다. 계정에 실제로 할당되는 금액이 우선합니다.',
    },
    UserInfo: {
        Wallets_desc: '지갑',
        Reward_desc: '보상',
        half_month: '15일',
        one_month: '1개월',
        three_month: '3개월',
        Invitees: '초대자',
        no_data: '데이터 없음',
        walletNo_support: '지원되지 않는 지갑입니다. 다른 지갑 앱을 사용해주세요.',
        network_wrong: '올바른 네트워크를 선택하세요',
      },
}
export default ko_KR

// Datajson:{
//     language_json:{
//         zh_json:'중국어 간체',
//         zh_FT_json: '중국어 번체',
//         eh_json:'영어',
//         ja_JP_json:'일본어',  
//         ko_KR_json:'한국어' ,
//         en_TH_json:'타이 어로',
//         fr_FR_json:'프랑스어',
//         ru_RU_json:'러시아어',
//         en_IN_json:'힌디어',
//         en_ID_json:'인도네시아어',
//         en_BS_json:'페르시아어',
//         en_YN_json:'베트남어',
//         en_ALB_json:'아랍어로',
//         en_TEQ_json:'터키어로',
//         en_ES_json:'스페인어',
//         en_PTY_json:'포르투갈어로',
//         en_YDL_json:'이탈리아어로'
//     },
// },