const en_IN = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'भाषा',
        fduse_desc: 'FDUSD एक 1:1 यूएसडी समर्थित स्थिर मुद्रा है।',
        introduce_desc: 'कटिंग-एज डिजिटल स्थिर मुद्रा के साथ वैश्विक वित्त को क्रांतिकारी बनाएं, दुनिया भर में व्यापार और निर्माताओं को सुरक्षा, गति और नवाचार प्रदान करें।',
        getfduse_desc: 'FDUSD प्राप्त करें',
        stability_desc: 'स्थिरता नवाचार से मिलती है',
        experience_desc: 'लाइटनिंग-फास्ट गति और न्यूनतम लागतों का अनुभव करें। हमारे नवाचारी और विश्वसनीय समाधान के साथ वित्त का भविष्य आकार दें।',
        whitepaper_desc: 'व्हाइटपेपर',
        redeemable_desc: '1:1 यूएसडी के लिए पुनर्प्राप्त किया जा सकता है',
        dollars_desc: 'FDUSD का उद्देश्य पूर्णतः कैश और कैश समकक्ष उपजों द्वारा समर्थित होना है। टोकन 1:1 यूएसडी के लिए पुनर्प्राप्त किए जाने का उद्देश्य है।',
        backed_desc: 'पूर्णतः आरक्षित उपजों द्वारा समर्थित',
        deserved_desc: 'आरक्षित उपज होल्डिंग संरचनाओं में पूर्णरूप से संग्रहीत और दिवालियां-रिमोट रखने योग्य हैं।',
        programmable_desc: 'पूरी तरह से प्रोग्राम करने योग्य',
        innovative_desc: 'हमारे नवाचारी स्थिर मुद्रा समाधान के साथ - उन्नत प्रौद्योगिकी द्वारा समर्थित, हम एक सुरक्षित और विश्वसनीय विकल्प प्रदान करने का उद्देश्य रखते हैं जो वित्त जगत में शांति लाता है।',
        settle_desc: 'मिनटों में बस्ता है',
        nearZero_desc: 'निकट-शून्य लागत',
        always_desc: 'हमेशा सक्रिय, 24/7',
        fully_desc: "पूरा प्रोग्रामेबल",
        fduse_desc: 'FDUSD एक 1:1 यूएसडी समर्थित स्थिर मुद्रा है।',
        introduce_desc: 'कटिंग-एज डिजिटल स्थिर मुद्रा के साथ वैश्विक वित्त को क्रांतिकारी बनाएं, दुनिया भर में व्यापार और निर्माताओं को सुरक्षा, गति, और नवाचार प्रदान करें।',
        getfduse_desc: 'FDUSD प्राप्त करें',
        stability_desc: 'स्थिरता में नवाचार',
        experience_desc: 'लाइटनिंग-फास्ट गति और न्यूनतम लागत का अनुभव करें। हमारे नवाचारिक और विश्वसनीय समाधान के साथ वित्त के भविष्य को आकार दें।',
        whitepaper_desc: 'व्हाइटपेपर',
        redeemable_desc: '1:1 यूएसडी के लिए पुनर्प्राप्त किया जा सकता है',
        dollars_desc: 'FDUSD पूर्णतः कैश और कैश समकक्ष उपजों द्वारा समर्थित होने का उद्देश्य है। टोकन 1:1 यूएसडी के लिए पुनर्प्राप्त किए जाने का उद्देश्य है।',
        backed_desc: 'पूर्णतः आरक्षित उपजों द्वारा समर्थित',
        deserved_desc: 'आरक्षित उपजों को पूर्णतः अलग करके दिवालियां-रिमोट होल्डिंग संरचनाओं में संग्रहीत किया जाता है।',
        programmable_desc: 'पूरी तरह से प्रोग्राम करने योग्य',
        innovative_desc: 'हमारे नवाचारिक स्थिर मुद्रा समाधान के साथ - उन्नत प्रौद्योगिकी द्वारा समर्थित, हम सुरक्षित और विश्वसनीय विकल्प प्रदान करने का उद्देश्य रखते हैं जो वित्त जगत में शांति लाता है।',
        settle_desc: 'मिनटों में बस्ता है',
        nearZero_desc: 'निकट-शून्य लागत',
        always_desc: 'हमेशा सक्रिय, 24/7',
        fully_desc: "पूरी तरह से प्र",
    },
    modal: {
        hold_desc: 'यदि आप होल्ड करते हैं',
        Smart_desc: 'BNB स्मार्ट चेन के, तो आपको बोनस रिवार्ड मिलेगा,',
        Rewards_desc: 'रिवार्ड्स केवल उन राशियों के लिए उपलब्ध हैं जो',
        address_desc: 'पता होल्ड करता है',
        hours_desc: '24 घंटे के लिए, और न्यूनतम होल्डिंग प्रणाली के यादृच्छिक स्नैपशॉट पर आधारित है। राशि आवंटित की जाती है, हर 24 घंटे एक बार। खाते में वास्तविक आवंटन प्रमुख रहेगा।'
    },
    UserInfo: {
        Wallets_desc: 'वॉलेट',
        Reward_desc: 'पुरस्कार',
        half_month: '15 दिन',
        one_month: '1 महीना',
        three_month: '3 महीने',
        Invitees: 'मेहमानों',
        no_data: 'कोई डेटा नहीं',
        walletNo_support: 'आपका वॉलेट समर्थित नहीं है। कृपया दूसरा वॉलेट ऐप का उपयोग करें।',
        network_wrong: 'कृपया सही नेटवर्क चुनें',
      },
}
export default en_IN

// Datajson:{
//     language_json:{
//         zh_json:'सरल चीनीName',
//         zh_FT_json: 'पारंपरिक चीनीName',
//         eh_json:'अंग्रेजीName',
//         ja_JP_json:'जापानीName',  
//         ko_KR_json:'कोरियाई@ item Text character set' ,
//         en_TH_json:'थाई',
//         fr_FR_json:'फ्रेंच',
//         ru_RU_json:'रूसीName',
//         en_IN_json:'हिंदीName',
//         en_ID_json:'इंडोनेशियाName',
//         en_BS_json:'फार्सी',
//         en_YN_json:'विएतनामीName',
//         en_ALB_json:'अरबीKCharselect unicode block name',
//         en_TEQ_json:'तुर्की',
//         en_ES_json:'स्पेनिश',
//         en_PTY_json:'पुर्तगालीName',
//         en_YDL_json:'इटालियनName'
//     },
// },