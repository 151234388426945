const staking = {
    state: {
      // 状态内容
      project_key:'fdusd_fdusd'
    },
    mutations: {
      // mutations
    },
    actions: {
      // actions
      projectKeyStorage(content){
        localStorage.setItem('project_key',content.project_key)
      }
    },
    getters: {
      // getters
    },
  };
  
  export default staking;