const en_YN = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: 'ngôn ngữ',
        fduse_desc: 'FDUSD là một Stablecoin được hỗ trợ bằng USD tỷ lệ 1:1.',
        introduce_desc: 'Cách mạng hóa tài chính toàn cầu với một stablecoin kỹ thuật số tiên tiến, cung cấp an ninh, tốc độ và sự đổi mới trên toàn thế giới cho doanh nghiệp và nhà xây dựng.',
        getfduse_desc: 'Nhận FDUSD',
        stability_desc: 'Sự ổn định gặp gỡ sự đổi mới',
        experience_desc: 'Trải nghiệm tốc độ nhanh chóng và chi phí tối thiểu. Hình thành tương lai tài chính với giải pháp đổi mới và đáng tin cậy của chúng tôi.',
        whitepaper_desc: 'Bản giấy trắng',
        redeemable_desc: 'Đổi 1:1 thành đô la Mỹ',
        dollars_desc: 'FDUSD được thiết kế để được hỗ trợ đầy đủ bằng tiền mặt và tài sản tương đương tiền mặt. Token được thiết kế để có thể đổi 1:1 thành đô la Mỹ.',
        backed_desc: 'Được hỗ trợ bởi tài sản dự trữ đầy đủ',
        deserved_desc: 'Các tài sản dự trữ được giữ trong cơ cấu nắm giữ riêng biệt và không bị ảnh hưởng bởi phá sản.',
        programmable_desc: 'Hoàn toàn có thể lập trình',
        innovative_desc: 'Với giải pháp stablecoin đổi mới của chúng tôi - được hỗ trợ bởi công nghệ tiên tiến, chúng tôi nhằm mục tiêu cung cấp một lựa chọn an toàn và đáng tin cậy mang lại sự yên tâm, tạo ra một tác động tích cực trong thế giới tài chính.',
        settle_desc: 'Thanh toán trong vài phút',
        nearZero_desc: 'Chi phí gần như bằng không',
        always_desc: 'Luôn sẵn sàng, 24/7',
        fully_desc: "Hoàn toàn có thể lập trình",
        rewrite_desc: 'Viết lại Tương lai với First Digital Labs',
        combining_desc: 'Kết hợp sự ổn định với sự đổi mới tại First Digital Labs, chúng tôi cam kết xây dựng niềm tin bằng cách cung cấp sự minh bạch, tuân thủ và an ninh không dao động được hỗ trợ bằng công nghệ tiên tiến.',
        mission_desc: 'Chúng tôi đang trên một nhiệm vụ thúc đẩy ngành tài chính vào một kỷ nguyên tài chính mới và tái hình thành cảnh quan tài chính toàn cầu.',
        should_desc: 'Tại sao bạn nên sử dụng First Digital USD?',
        collateral_desc: 'Tài sản thế chấp được bảo vệ bởi một ngân hàng giữ tầng hàng đầu, có giấy phép đáng tin cậy để bảo vệ tài sản một cách liền mạch và tuân thủ quy định.',
        transparent_desc: 'Minh bạch',
        independent_desc: 'Xác nhận độc lập - tài sản thế chấp được xác nhận bởi bên thứ ba độc lập, đảm bảo tính toàn vẹn của dự trữ và báo cáo tài chính.',
        examined_by_desc: 'Được kiểm tra bởi',
        attestation_Reports_desc: 'Báo cáo xác nhận',
        January2024_desc: 'Tháng 1 năm 2024',
        Report_desc: 'Báo cáo',
        December_desc: 'Tháng 12 năm 2023',
        November_desc: 'Tháng 11 năm 2023',
        Networks_desc: 'Các Mạng được Hỗ trợ?',
        available_desc: 'FDUSD có sẵn trên',
        Ethereum_desc: 'Ethereum',
        and_desc: 'và',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'với kế hoạch hỗ trợ cho một số lượng ngày càng tăng của các blockchain.',
        Audited_desc: 'Được kiểm toán bởi',
        Frequently_desc: 'Câu hỏi thường gặp',
        How_FDUSD_desc: 'Làm thế nào để tôi có thể có được FDUSD?',
        Acquiring_desc: 'Việc sở hữu FDUSD có thể được thực hiện theo một vài cách. Nếu bạn là một người chơi quan trọng trong ngành, một trung gian tài chính hoặc một nhà đầu tư chuyên nghiệp có địa vị nhất định và đáp ứng các tiêu chí cần thiết, bạn có thể mua FDUSD trực tiếp từ First Digital Labs. Vui lòng lưu ý rằng First Digital Labs không bán token trực tiếp cho khách hàng bán lẻ.:',
        canstill_desc: 'Bạn vẫn có thể có được FDUSD thông qua thị trường phụ không trở thành khách hàng của First Digital Labs. Nhiều sàn giao dịch tiền điện tử hàng đầu liệt kê FDUSD và cung cấp thanh khoản đáng kể. Điều này cung cấp một cách tiếp cận và thuận tiện cho những người quan tâm để sở hữu FDUSD. Tuy nhiên, luôn luôn cần thực hiện nghiên cứu kỹ lưỡng và đảm bảo sự đáng tin cậy của sàn giao dịch mà bạn đang sử dụng.',
        equivalent_desc: 'Làm thế nào để tôi có thể tin rằng FDUSD luôn luôn tương đương với đô la?',
        stablecoin_desc: 'Sự tin tưởng của bạn vào giá trị stablecoin FDUSD là có căn cứ vững chắc. Mỗi FDUSD được thiết đặt để được đảm bảo đầy đủ bằng một đô la Mỹ hoặc một tài sản có giá trị công bằng tương đương. Những tài sản này được giữ an toàn bởi một ngân hàng giữ tầng được chấp thuận trong tài khoản với các cơ sở lưu ký được quy định, tạo nền tảng vững chắc cho giá trị của đồng tiền này. Hệ thống này là điều cung cấp cho FDUSD tính ổn định bẩm sinh và tại sao bạn có thể tin tưởng giá trị tương đương của nó với đô la Mỹ.',
        redeem_FDUSD: 'Làm thế nào để tôi có thể đổi FDUSD?',
        stablecoin_desc: 'Để đổi FDUSD stablecoin của bạn, bạn phải trở thành khách hàng của First Digital Labs và đáp ứng các yêu cầu cụ thể, bao gồm kiểm tra chống rửa tiền (AML) và chống tài chính khủng bố (CTF). Sau khi hoàn thành thành công các kiểm tra này, bạn có thể đổi FDUSD của mình thành tiền tệ tương đương, do đó, lấy nó ra khỏi lưu thông. Hoặc bạn có thể bán các token FDUSD của mình trên thị trường phụ qua sàn giao dịch tiền điện tử hoặc nhà cung cấp Over-the-Counter (OTC) hỗ trợ stablecoin FD121',
    },
    modal: {
        hold_desc: 'Nếu bạn nắm giữ',
        Smart_desc: 'của BNB Smart Chain, bạn sẽ nhận được phần thưởng thêm,',
        Rewards_desc: 'Phần thưởng chỉ có sẵn cho số lượng vượt quá',
        address_desc: 'Địa chỉ nắm giữ',
        hours_desc: 'trong vòng 24 giờ, và số lượng tối thiểu được dựa trên chụp ảnh ngẫu nhiên của hệ thống. Số tiền được phân bổ, mỗi 24 giờ một lần. Số tiền phân bổ thực tế cho tài khoản sẽ được ưu tiên.',
    },
    UserInfo: {
        Wallets_desc: 'Ví',
        Reward_desc: 'Phần thưởng',
        half_month: '15 Ngày',
        one_month: '1 tháng',
        three_month: '3 tháng',
        Invitees: 'Người được mời',
        no_data: 'Không có dữ liệu',
        walletNo_support: 'Ví của bạn không được hỗ trợ. Vui lòng sử dụng ứng dụng ví khác.',
        network_wrong: 'Vui lòng chọn đúng mạng',
      },
}
export default en_YN

// Datajson:{
//     language_json:{
//         zh_json:'Trung Quốc giản thể',
//         zh_FT_json: 'Trung Quốc truyền thống',
//         eh_json:'Tiếng anh',
//         ja_JP_json:'Tiếng nhật',  
//         ko_KR_json:'Tiếng hàn' ,
//         en_TH_json:'Quận taita tiếng',
//         fr_FR_json:'Tiếng pháp',
//         ru_RU_json:'Tiếng nga',
//         en_IN_json:'Hindi',
//         en_ID_json:'Tiếng Indonesia',
//         en_BS_json:'Ba tư',
//         en_YN_json:'Việt nam',
//         en_ALB_json:'Ả rập',
//         en_TEQ_json:'Thổ nhĩ kỳ',
//         en_ES_json:'Tiếng tây ban nha',
//         en_PTY_json:'Tiếng bồ đào nha',
//         en_YDL_json:'Tiếng ý.'
//     },
// },