//泰语
const en_TH = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'ภาษา',
        fduse_desc: 'FDUSD เป็น Stablecoin ที่ผสมเงินดอลลาร์สหรัฐ 1:1',
        introduce_desc: 'เปลี่ยนแปลงทางการเงินระดับโลกด้วย Stablecoin ที่ทันสมัยและทันสถานการณ์ ให้ธุรกิจและผู้สร้างสรรค์ได้รับความปลอดภัย ความเร็ว และนวัตกรรมทั่วโลก',
        getfduse_desc: 'รับ FDUSD',
        stability_desc: 'ความเสถียรต่อการประยุกต์ใช้นวัตกรรม',
        experience_desc: 'สัมผัสความเร็วแบบฟ้าผ่า และค่าใช้จ่ายต่ำสุด รูปแบบที่นำสมัยในการเงินด้วยความน่าเชื่อถือและนวัตกรรม',
        whitepaper_desc: 'เอกสารขาว',
        redeemable_desc: 'สามารถแลกคืนดอลลาร์สหรัฐ 1:1',
        dollars_desc: 'FDUSD ได้รับการสนับสนุนจากเงินสดและสินทรัพย์เทียบเท่าเงินสด โทเค็นสามารถแลกคืนดอลลาร์สหรัฐ 1:1',
        backed_desc: 'รับรองด้วยสินทรัพย์ที่เก็บไว้เต็มที่',
        deserved_desc: 'สินทรัพย์ที่เก็บไว้ถูกจัดเก็บในโครงสร้างการถือครองที่ปกครองตนเองและปกครองตนเองจากการล้มละลาย',
        programmable_desc: 'สามารถโปรแกรมได้เต็มที่',
        innovative_desc: 'ด้วยวิธีการสกัดเงินอย่างล้ำสมัยของเรา ซึ่งได้รับการสนับสนุนจากเทคโนโลยีที่ทันสมัย เรามุ่งหวังที่จะให้ทางเลือกที่ปลอดภัยและน่าเชื่อถือในการเงิน และสร้างผลกระทบที่ดีในโลกของการเงิน',
        settle_desc: 'เรียกเก็บในไม่กี่นาที',
        nearZero_desc: 'ค่าใช้จ่ายใกล้เคียงศูนย์',
        always_desc: 'เชื่อมต่อตลอดเวลา 24/7',
        fully_desc: "สามารถโปรแกรมได้เต็มที่",
        rewrite_desc: 'เขียนประวัติศาสตร์ด้วย First Digital Labs',
        combining_desc: 'รวมความเสถียรให้กับนวัตกรรมที่ First Digital Labs เรามุ่งมั่นที่จะได้รับความเชื่อถือโดยการนำเสนอความโปร่งใส การปฏิบัติตามกฎหมาย และความปลอดภิมที่ได้รับการสนับสนุนจากเทคโนโลยีที่ทันสมัย',
        mission_desc: 'เรามีพันธกิจในการผลักดันอุตสาหกรรมการเงินเข้าสู่ยุคใหม่ของการเงินและเปลี่ยนรูปแบบทางการเงินระดับโลก',
        should_desc: 'ทำไมคุณควรใช้ First Digital USD?',
        collateral_desc: 'การมีหลักประกันได้รับการปกป้องด้วยผู้คุ้มครองที่มีคุณสมบัติระดับบนพร้อมทรัพย์สินในการป้องกันความเสี่ยงและการปฏิบัติตามกฎหมาย',
        transparent_desc: 'โปร่งใส',
        independent_desc: 'การรับรองโดยบุคคลที่สาม อิสระ - การตรวจสอบค่าหลักประกันโดยบุคคลที่สามที่ยืนยันความสอดคล้องของสินทรัพย์หลักและการรายงานทางการเงิน',
        examined_by_desc: 'ตรวจสอบโดย',
        attestation_Reports_desc: 'รายงานการรับรอง',
        January2024_desc: 'มกราคม 2024',
        Report_desc: 'รายงาน',
        December_desc: 'ธันวาคม 2023',
        November_desc: 'พฤศจิกายน 2023',
        Networks_desc: 'เครือข่ายใดที่ได้รับการสนับสนุน?',
        available_desc: 'FDUSD พร้อมใช้งานบน',
        Ethereum_desc: 'Ethereum',
        and_desc: 'และ',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'ด้วยการสนับสนุนที่วางแผนสำหรับจำนวนบล็อกเชนที่เพิ่มขึ้น',
        Audited_desc: 'ผ่านการตรวจสอบจาก',
        Frequently_desc: 'คำถามที่พบบ่อย',
        How_FDUSD_desc: 'ฉันจะได้รับ FDUSD อย่างไร?',
        Acquiring_desc: 'การเข้าถึง FDUSD สามารถทำได้หลายวิธี หากคุณเป็นผู้เล่นหลักในอุตสาหกรรม ผู้กลางทางการเงิน หรือนักลงทุนมืออาชีพที่มีฐานะที่แน่นอนและตรงตามเกณฑ์ที่กำหนดคุณสามารถซื้อ FDUSD โดยตรงจาก First Digital Labs โปรดทราบว่า First Digital Labs ไม่ขายโทเค็นโดยตรงให้กับลูกค้าทั่วไป.:',
        canstill_desc: 'คุณยังสามารถได้รับ FDUSD ผ่านทางตลาดรองโดยไม่ต้องเป็นลูกค้าของ First Digital Labs หลายแลกเปลี่ยนสกุลเงินดิจิตอลชั้นนำรายงาน FDUSD และให้สภาพคล่อง',
    },
    modal: {
        hold_desc: 'หากคุณถือ',
        Smart_desc: 'ของ BNB Smart Chain คุณจะได้รับรางวัลโบนัส',
        Rewards_desc: 'รางวัลสามารถใช้ได้เฉพาะสำหรับจำนวนที่เกิน',
        address_desc: 'ที่อยู่ถือ',
        hours_desc: 'เป็นเวลา 24 ชั่วโมง และการถือขั้นต่ำขึ้นอยู่กับสแนปช็อตสุ่มของระบบ จำนวนจะถูกจัดสรรอย่างน้อยหนึ่งครั้งต่อวัน การจัดสรรจริงๆ ขึ้นอยู่กับบัญชีที่ได้รับจัดสรร'
    },
    UserInfo: {
        Wallets_desc: 'กระเป๋าเงิน',
        Reward_desc: 'รางวัล',
        half_month: '15 วัน',
        one_month: '1 เดือน',
        three_month: '3 เดือน',
        Invitees: 'ผู้ถูกเชิญ',
        no_data: 'ไม่มีข้อมูล',
        walletNo_support: 'กระเป๋าเงินของคุณไม่รองรับ โปรดใช้แอปพลิเคชันกระเป๋าเงินอื่น',
        network_wrong: 'โปรดเลือกเครือข่ายที่ถูกต้อง',
      },
}
export default en_TH

// Datajson:{
//     language_json:{
//         zh_json:'ภาษาจีนตัวย่อ',
//         zh_FT_json: 'ภาษาจีนดั้งเดิม',
//         eh_json:'ภาษาอังกฤษ',
//         ja_JP_json:'ญี่ปุ่น',  
//         ko_KR_json:'เกาหลี' ,
//         en_TH_json:'ภาษาไทย',
//         fr_FR_json:'ฝรั่งเศส',
//         ru_RU_json:'ภาษารัสเซีย',
//         en_IN_json:'ภาษาฮินดี name',
//         en_ID_json:'ชาวอินโดนีเซีย',
//         en_BS_json:'เปอร์เซีย',
//         en_YN_json:'เวียดนาม',
//         en_ALB_json:'ภาษาอาหรับ',
//         en_TEQ_json:'ตุรกี',
//         en_ES_json:'สเปน',
//         en_PTY_json:'โปรตุเกส',
//         en_YDL_json:'อิตาลี'
//     },
// },