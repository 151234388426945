const en_TEQ = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'dil',
        fduse_desc: "FDUSD, 1:1 ABD Doları destekli bir Stablecoin'dir.",
        introduce_desc: 'Küresel finansı son teknoloji dijital bir stablecoin ile devrimleştirerek, dünyadaki işletmelere ve geliştiricilere güvenlik, hız ve yenilik sunuyoruz.',
        getfduse_desc: 'FDUSD Edinin',
        stability_desc: 'Stabilite Yenilikle Buluşuyor',
        experience_desc: 'Hızlı işlem hızı ve minimum maliyetlerin keyfini çıkarın. Yenilikçi ve güvenilir çözümümüzle finansın geleceğini şekillendirin.',
        whitepaper_desc: 'Beyaz Kağıt',
        redeemable_desc: 'ABD doları karşılığında 1:1 olarak geri alınabilir',
        dollars_desc: "FDUSD, tamamen nakit ve nakit benzeri varlıklarla desteklenmesi amaçlanan bir stablecoin'dir. Jetonlar, ABD doları karşılığında 1:1 olarak geri alınabilir olması amaçlanmaktadır.",
        backed_desc: 'Tamamen rezerve dayalı',
        deserved_desc: 'Rezerve varlıklar, tamamen ayrılmış iflas dışı tutma yapılarında tutulmaktadır.',
        programmable_desc: 'Tamamen Programlanabilir',
        innovative_desc: 'Gelişmiş teknoloji tarafından desteklenen yenilikçi stablecoin çözümümüzle, finans dünyasında huzur ve güven sağlayan güvenli ve güvenilir bir alternatif sunmayı hedefliyoruz.',
        settle_desc: 'Dakikalar İçinde Hesaplaşma',
        nearZero_desc: 'Neredeyse Sıfır Maliyet',
        always_desc: 'Her Zaman Açık, 7/24',
        fully_desc: 'Tamamen Programlanabilir',
        rewrite_desc: 'First Digital Labs ile Geleceği Yeniden Yazın',
        combining_desc: "Stabiliteyi yenilikle birleştirerek, First Digital Labs'da, kesintisiz şeffaflık, uyumluluk ve güvenlik sunarak güven kazanmaya adanmışız.",
        mission_desc: 'Finans sektörünü finansal bir çağa taşımak ve küresel finansal manzarayı yeniden şekillendirmek için bir görevdeyiz.',
        should_desc: "Neden First Digital USD'yi Kullanmalısınız?",
        collateral_desc: 'Teminat, düzenlemeli bir depolama kuruluşunun güven lisansına sahip, en üst düzeyde nitelikli bir emanetçi tarafından korunmaktadır.',
        transparent_desc: 'Şeffaf',
        independent_desc: 'Bağımsız denetim - teminat, bağımsız bir üçüncü taraf tarafından doğrulanır, bu da rezervlerin ve mali raporlamanın bütünlüğünü sağlar.',
        examined_by_desc: 'Tarafından incelenmiştir',
        attestation_Reports_desc: 'Doğrulama Raporları',
        January2024_desc: 'Ocak 2024',
        Report_desc: 'Rapor',
        December_desc: 'Aralık 2023',
        November_desc: 'Kasım 2023',
        Networks_desc: 'Hangi Ağlar Desteklenmektedir?',
        available_desc: 'FDUSD,',
        Ethereum_desc: 'Ethereum',
        and_desc: 've',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'planned support for an increasing number of blockchains.',
        Audited_desc: 'Denetlenmiştir',
        Frequently_desc: 'Sıkça Sorulan Sorular',
        How_FDUSD_desc: 'FDUSD Nasıl Alabilirim?',
        Acquiring_desc: "FDUSD edinme işlemi birkaç farklı şekilde gerçekleştirilebilir. Eğer önemli bir endüstri oyuncusu, bir finansal aracı veya belirli bir statüye sahip bir profesyonel yatırımcıysanız ve gerekli kriterleri karşılıyayorsanız, FDUSD'yi doğrudan First Digital Labs'tan satın alabilirsiniz. Lütfen unutmayın ki, First Digital Labs doğrudan perakende müşterilere jeton satmamaktadır.",
        canstill_desc: "First Digital Labs müşterisi olmadan da FDUSD elde edebilirsiniz. Birçok önde gelen kripto para borsası FDUSD'yi listeleyerek ve büyük likidite sunarak erişilebilir ve uygun bir şekilde FDUSD elde etmek isteyen bireyler için kolay bir yol sağlar. Bununla birlikte, kullanacağınız borsanın güvenilirliğini sağlamak için detaylı araştırma yapmanız ve güvence sağlamanız önemlidir.",
        equivalent_desc: "FDUSD'nin her zaman bir dolara eşit olacağına nasıl güvenebilirim?",
        stablecoin_desc: "FDUSD stablecoin'inin değerine olan güveniniz sağlam temellere dayanmaktadır. Her bir FDUSD'nin tamamen bir ABD doları veya eşit değerde bir varlık tarafından desteklenmesi amaçlanmaktadır. Bu varlıklar, düzenlenmiş depolama kuruluşlarındaki hesaplarda güvenli bir şekilde saklanır ve nitelikli bir emanetçi tarafından sağlanır, bu da jetonun değerine sağlam bir temel oluşturur. Bu sistem, FDUSD'ye içsel istikrarını sağlar ve onun ABD dolarına eşit değerine güvenebileceğiniz anlamına gelir.",
        redeem_FDUSD: 'FDUSD Nasıl Geri Alabilirim?',
        stablecoin_desc: "FDUSD stablecoin'inizi geri almak için öncelikle First Digital Labs müşterisi olmanız ve Anti-Para Aklama (AML) ve Terörizmin Finansmanı (CTF) kontrolleri de dahil olmak üzere belirli gereksinimleri karşılamanız gerekmektedir. Bu kontrolleri başarıyla tamamladıktan sonra, FDUSD'nizi fiat para birimindeki karşılığıyla değiştirebilir ve böylece dolaşımdan çıkarabilirsiniz. Alternatif olarak, FDUSD jetonlarınızı FD121'in stablecoin'lerini destekleyen bir kripto para borsası veya tezgah üstü (OTC) sağlayıcı aracılığıyla ikincil piyasada satabilirsiniz. İşlem yapmadan önce yargı alanınızdaki tüm yasal ve finansal düzenlemelere dikkat edin.",
        Explore_desc: 'Ekosistemimizi Keşfedin',
        Experience_desc: 'Güvenilir stablecoin çözümümüzle istikrarı deneyimleyin.',
        slide_desc: 'sayfa',
        _1to6_desc: '1 ile 6',
        of7_desc: 'arasi',
        Transparency_desc: 'Şeffaflık',
        Legal_desc: 'Yasal',
        Compliance_desc: 'Uyumluluk',
        Policy_desc: 'Gizlilik Politikası',
        Company_desc: 'Şirket',
        About_Us_desc: 'Hakkımızda',
        Social_Media_desc: 'Sosyal Medya',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Önemli Açıklamalar:',
        Limited_desc: 'FD121 Limited bir finansal teknoloji şirketidir, bir banka değildir. Rezervlerin saklanması hizmeti',
        First_desc: 'First Digital Trust Limited',
        product_desc: 'Tüm ürün ve şirket adları, sahiplerinin ticari markalarıdır veya tescilli ticari markalardır. Bu web sitesinde kullanılan tüm şirket, ürün ve hizmet adları yalnızca tanımlama amaçlıdır. Bu isimlerin, ticari markaların ve markaların herhangi biriyle bağlantı veya onay anlamına gelmez.',
        additional_desc: "Ek önemli riskler, açıklamalar ve bilgiler için lütfen ziy",
    },
    modal: {
        hold_desc: 'Eğer',
        Smart_desc: "BNB Smart Chain'de tutuyorsanız, bonus ödülünüz olacak",
        Rewards_desc: 'Ödüller,',
        address_desc: 'Adres',
        hours_desc: '24 saat boyunca toplamak için ve minimum tutma rastgele sistem anlık görüntüsüne dayanmaktadır. Miktar, her 24 saatte bir tahsis edilir. Gerçek tahsis hesaba geçerlidir.'
    },
    UserInfo: {
        Wallets_desc: 'Cüzdanlar',
        Reward_desc: 'Ödül',
        half_month: '15 Gün',
        one_month: '1 ay',
        three_month: '3 ay',
        Invitees: 'Davetliler',
        no_data: 'Veri Yok',
        walletNo_support: 'Cüzdanınız desteklenmiyor. Lütfen başka bir cüzdan uygulaması kullanın.',
        network_wrong: 'Lütfen doğru ağı seçin',
      },
}
export default en_TEQ

// Datajson:{
//     language_json:{
//         zh_json:'Basitleştirilmiş Çince',
//         zh_FT_json: 'Traditional Chinese',
//         eh_json:'İngilizce',
//         ja_JP_json:'Japonca',  
//         ko_KR_json:'Korece' ,
//         en_TH_json:'Tayland',
//         fr_FR_json:'Fransızca',
//         ru_RU_json:'Rusça',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonezi',
//         en_BS_json:'Farsça',
//         en_YN_json:'Vietnamca',
//         en_ALB_json:'Arapça',
//         en_TEQ_json:'Türkçe',
//         en_ES_json:'İspanyolca',
//         en_PTY_json:'Portekizce',
//         en_YDL_json:'İtalyanca'
//     },
// },