const en_YDL = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: 'lingua',
        fduse_desc: 'FDUSD è una stablecoin 1:1 ancorata al dollaro USA.',
        introduce_desc: "Rivoluziona la finanza globale con una stablecoin digitale all'avanguardia, fornendo sicurezza, velocità e innovazione a livello mondiale per le aziende e i costruttori.",
        getfduse_desc: 'Ottieni FDUSD',
        stability_desc: "Stabilità incontra l'innovazione",
        experience_desc: 'Vivi velocità fulminea e costi minimi. Dà forma al futuro della finanza con la nostra soluzione innovativa e affidabile.',
        whitepaper_desc: 'Whitepaper',
        redeemable_desc: 'Riscattabile 1:1 per dollari statunitensi',
        dollars_desc: 'FDUSD è destinato ad essere completamente garantito da contanti e attività equivalenti in contanti. I token sono destinati a essere riscattabili 1:1 per dollari statunitensi.',
        backed_desc: 'Supportato da riserve completamente riservate',
        deserved_desc: 'Le riserve sono detenute in strutture di conservazione completamente separate e al riparo da fallimenti.',
        programmable_desc: 'Completamente programmabile',
        innovative_desc: "Con la nostra soluzione innovativa di stablecoin - supportata da tecnologia avanzata, miriamo a fornire un'alternativa sicura e affidabile che porti tranquillità, facendo un impatto positivo nel mondo della finanza.",
        settle_desc: 'Liquidazione in pochi minuti',
        nearZero_desc: 'Costo vicino allo zero',
        always_desc: 'Sempre attivo, 24/7',
        fully_desc: "Completamente programmabile",
        rewrite_desc: 'Riscrivi il futuro con First Digital Labs',
        combining_desc: "Unendo stabilità e innovazione presso First Digital Labs, ci impegniamo a guadagnare fiducia offrendo una trasparenza inossidabile, conformità e sicurezza supportate da tecnologia all'avanguardia.",
        mission_desc: "Siamo impegnati in una missione per spingere l'industria finanziaria verso una nuova era della finanza e ridisegnare il panorama finanziario globale.",
        should_desc: 'Perché dovresti usare First Digital USD?',
        collateral_desc: 'Il margine è protetto da un custode qualificato di alto livello con una licenza fiduciaria per una protezione agevole degli asset e la conformità alle normative.',
        transparent_desc: 'Trasparente',
        independent_desc: "Accertamento indipendente - il margine è convalidato da una terza parte indipendente, garantendo l'integrità delle riserve e la rendicontazione finanziaria.",
        examined_by_desc: 'Esaminato da',
        attestation_Reports_desc: 'Rapporti di attestazione',
        January2024_desc: 'Gennaio 2024',
        Report_desc: 'Rapporto',
        December_desc: 'Dicembre 2023',
        November_desc: 'Novembre 2023',
        Networks_desc: 'Quali reti sono supportate?',
        available_desc: 'FDUSD è disponibile su',
        Ethereum_desc: 'Ethereum',
        and_desc: 'e',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'con il supporto pianificato per un numero crescente di blockchain.',
        Audited_desc: 'Sottoposto a revisione da parte di',
        Frequently_desc: 'Domande frequenti',
        How_FDUSD_desc: 'Come posso ottenere FDUSD?',
        Acquiring_desc: "L'acquisizione di FDUSD può essere effettuata in diverse modalità. Se sei un attore chiave nel settore, un intermediario finanziario o un investitore professionale di determinato rango e soddisfi i criteri richiesti, puoi acquistare direttamente FDUSD da First Digital Labs. Si prega di notare che First Digital Labs non vende direttamente i token ai clienti al dettaglio.:",
        canstill_desc: "È comunque possibile ottenere FDUSD attraverso mercati secondari senza diventare un cliente di First Digital Labs. Molti importanti exchange di criptovalute elencano FDUSD e offrono una liquidità sostanziale. Questo offre un modo accessibile e conveniente per gli individui interessati di acquisire FDUSD. Tuttavia, è sempre essenziale condurre ricerche approfondite e garantire la credibilità dell'exchange che si sta utilizzando.",
        equivalent_desc: 'Come posso fidarmi che FDUSD sarà sempreequivalente a un dollaro?',
        stablecoin_desc: 'La tua fiducia nel valore della stablecoin FDUSD è ben fondata. Ogni FDUSD è destinato ad essere completamente garantito da un dollaro statunitense o da un asset di valore equo equivalente. Questi asset sono custoditi in modo sicuro da un custode qualificato in conti presso istituti depositari regolamentati, fornendo una solida base per il valore della moneta. Questo sistema è ciò che conferisce a FDUSD la sua stabilità intrinseca e perché puoi fidarti del suo valore equivalente al dollaro statunitense.',
        redeem_FDUSD: 'Come posso riscattare FDUSD?',
        stablecoin_desc: 'Per riscattare la tua stablecoin FDUSD, devi prima diventare un cliente di First Digital Labs e soddisfare requisiti specifici, inclusi controlli antiriciclaggio (AML) e finanziamento del terrorismo (CTF). Al termine di questi controlli, puoi scambiare i tuoi FDUSD con il loro equivalente in valuta fiat, rimuovendoli così dalla circolazione. In alternativa, puoi vendere i tuoi token FDUSD sul mercato secondario tramite un exchange di criptovalute o un fornitore over-the-counter (OTC) che supporta le stablecoin FD121. Prima di procedere, ti preghiamo di essere consapevole di tutte le normative legali e finanziarie nella tua giurisdizione.',
        Explore_desc: "Esplora il nostro ecosistema",
        Experience_desc: 'Vivi la stabilità con la nostra affidabile soluzione di stablecoin.',
        slide_desc: 'diapositiva',
        _1to6_desc: 'da 1 a 6',
        of7_desc: 'di 7',
        Transparency_desc: 'Trasparenza',
        Legal_desc: 'Legale',
        Compliance_desc: 'Conformità',
        Policy_desc: 'Politica sulla privacy',
        Company_desc: 'Azienda',
        About_Us_desc: 'Chi siamo',
        Social_Media_desc: 'Social Media',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Informazioni importanti:',
        Limited_desc: 'FD121 Limited è una società di tecnologia finanziaria, non una banca. I servizi di custodia delle riserve sono forniti da ',
        First_desc: "First Digital Trust Limited",
        product_desc: "Tutti i nomi di prodotti e aziende sono marchi ™ o marchi registrati® dei rispettivi titolari. Tutti i nomi di aziende, prodotti e servizi utilizzati in questo sito web sono solo a scopo di identificazione. L'uso di questi nomi, marchi e marchi commerciali non implica alcuna affiliazione o approvazione da parte loro.",
        additional_desc: 'Per ulteriori rischi, rivelazioni e informazioni importanti, visitare',
        Copyright_desc: 'Copyright © 2024 FD121 Limited. Tutti i diritti riservati.'
    },
    modal: {
        hold_desc: 'Se possiedi',
        Smart_desc: 'della BNB Smart Chain, avrai una ricompensa bonus,',
        Rewards_desc: 'Le ricompense sono disponibili solo per importi superiori a',
        address_desc: "L'indirizzo detiene",
        hours_desc: "per 24 ore, e il deposito minimo si basa sulla istantanea casuale del sistema. L'importo viene allocato, una volta ogni 24 ore. L'allocazione effettiva sul conto è quella che prevale."
    },
    UserInfo: {
        Wallets_desc: 'Portafogli',
        Reward_desc: 'Premio',
        half_month: '15 Giorni',
        one_month: '1 mese',
        three_month: '3 mesi',
        Invitees: 'Invitati',
        no_data: 'Nessun dato',
        walletNo_support: 'Il tuo portafoglio non è supportato. Si prega di utilizzare un\'altra app di portafoglio.',
        network_wrong: 'Seleziona la rete corretta',
      },
}
export default en_YDL
// Datajson:{
//     language_json:{
//         zh_json:'Cinese semplificato',
//         zh_FT_json: 'Cinese tradizionale',
//         eh_json:'Inglese',
//         ja_JP_json:'Giapponese',
//         ko_KR_json:'Coreano' ,
//         en_TH_json:'Thailandese',
//         fr_FR_json:'Francese',
//         ru_RU_json:'Russo',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonesiano',
//         en_BS_json:'Persiano',
//         en_YN_json:'Vietnamita',
//         en_ALB_json:'Arabo',
//         en_TEQ_json:'Turco',
//         en_ES_json:'Spagnolo',
//         en_PTY_json:'Portoghese',
//         en_YDL_json:'Italiano'
//     },
// },