//法语
const fr_FR = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: 'langue',
        fduse_desc: 'FDUSD est une stablecoin adossée au dollar américain à un taux de change de 1:1.',
        introduce_desc: 'Révolutionnez la finance mondiale avec une stablecoin numérique de pointe, offrant aux entreprises et aux développeurs une sécurité, une rapidité et une innovation sans précédent dans le monde entier.',
        getfduse_desc: 'Obtenez FDUSD',
        stability_desc: 'Stabilité et innovation',
        experience_desc: "Profitez de vitesses ultra-rapides et de frais minimes. Façonnez l'avenir de la finance avec notre solution innovante et fiable.",
        whitepaper_desc: 'Livre blanc',
        redeemable_desc: 'Échangeable 1:1 contre des dollars américains',
        dollars_desc: 'FDUSD est conçu pour être entièrement adossé à des espèces et à des actifs équivalents en espèces. Les jetons peuvent être échangés 1:1 contre des dollars américains.',
        backed_desc: 'Adossé à des actifs entièrement réservés',
        deserved_desc: 'Les actifs réservés sont détenus dans des structures de détention entièrement séparées et protégées contre les faillites.',
        programmable_desc: 'Entièrement programmable',
        innovative_desc: "Avec notre solution de stablecoin innovante - soutenue par une technologie de pointe, nous visons à fournir une alternative sûre et fiable qui apporte la tranquillité d'esprit et qui a un impact positif dans le monde de la finance.",
        settle_desc: 'Règlement en quelques minutes',
        nearZero_desc: 'Coût proche de zéro',
        always_desc: 'Toujours actif, 24/7',
        fully_desc: "Entièrement programmable",
        rewrite_desc: "Réécrivez l'avenir avec First Digital Labs",
        combining_desc: 'En combinant stabilité et innovation chez First Digital Labs, nous nous engageons à gagner la confiance en offrant une transparence, une conformité et une sécurité inébranlables soutenues par une technologie de pointe.',
        mission_desc: "Nous avons pour mission de propulser l'industrie financière dans une nouvelle ère de la finance et de remodeler le paysage financier mondial.",
        should_desc: 'Pourquoi devriez-vous utiliser le First Digital USD?',
        collateral_desc: "Les garanties sont protégées par un dépositaire de premier ordre qualifié disposant d'une licence de fiducie pour une protection transparente des actifs et une conformité réglementaire.",
        transparent_desc: 'Transparent',
        independent_desc: "Attestation indépendante - les garanties sont validées par un tiers indépendant, garantissant l'intégrité des réserves et des rapports financiers.",
        examined_by_desc: 'Examiné par',
        attestation_Reports_desc: "Rapports d'attestation",
        January2024_desc: 'Janvier 2024',
        Report_desc: 'Rapport',
        December_desc: 'Décembre 2023',
        November_desc: 'Novembre 2023',
        Networks_desc: 'Quels réseaux sont pris en charge?',
        available_desc: 'FDUSD est disponible sur',
        Ethereum_desc: 'Ethereum',
        and_desc: 'et',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'avec une prise en charge prévue pour un nombre croissant de blockchains.',
        Audited_desc: 'Vérifié par',
        Frequently_desc: 'Questions fréquemment posées',
        How_FDUSD_desc: 'Comment puis-je obtenir FDUSD?',
        Acquiring_desc: "L'acquisition de FDUSD peut être réalisée de plusieurs manières. Si vous êtes un acteur clé de l'industrie, un intermédiaire financier ou un investisseur professionnel d'un certain statut et que vous répondez aux critères requis, vous pouvez acheter directement FDUSD auprès de First Digital Labs. Veuillez noter que First Digital Labs ne vend pas directement de jetons aux clients de détail.:",
        canstill_desc: "Vous pouvez toujours obtenir FDUSD par le biais de marchés secondaires sans devenir client de First Digital Labs. De nombreuses principales plateformes d'échange de cryptomonnaies répertorient FDUSD et offrent une liquidité importante. Cela offre un moyen accessible et pratique aux personnes intéressées d'acquérir FDUSD. Il est cependant essentiel de mener des recherches approfondies et de garantir la crédibilité de l'échange que vous utilisez.",
        equivalent_desc: 'Comment puis-je avoir confiance que FDUSD sera toujours équivalent à un dollar?',
        stablecoin_desc: "Votre confiance dans la valeur stable de FDUSD est bien fondée. Chaque FDUSD est censé être entièrement adossé à un dollar américain ou à un actif de valeur équivalente. Ces actifs sont en sécurité chez un dépositaire qualifié dans des comptes auprès d'institutions de dépôt réglementées, ce qui garantit une base solide pour la valeur de la pièce. C'est ce système qui confère à FDUSD sa stabilité intrinsèque et vous permet de faire confiance à sa valeur équivalente au dollar américain.",
        redeem_FDUSD: 'Comment puis-je échanger FDUSD?',
        stablecoin_desc: "Pour échanger votre stablecoin FDUSD, vous devez d'abord devenir client de First Digital Labs et remplir des critères spécifiques, notamment des vérifications de lutte contre le blanchiment d'argent (AML) et de financement du terrorisme (FTC). Après avoir réussi ces vérifications, vous pouvez échanger vos FDUSD contre l'équivalent en monnaie fiduciaire, ce qui le retire de la circulation. Alternativement, vous pouvez vendre vos jetons FDUSD sur le marché secondaire via une plateforme d'échange de cryptomonnaies ou un fournisseur de gré à gré (OTC) qui prend en charge les stablecoins FD121. Veuillez prendre connaissance de toutes les réglementations légales et financières de votre juridiction avant de procéder.",
        Explore_desc: "Explorez notre écosystème",
        Experience_desc: "Faites l'expérience de la stabilité avec notre solution de stablecoin fiable.",
        slide_desc: 'diapositive',
        _1to6_desc: '1 à 6',
        of7_desc: 'sur 7',
        Transparency_desc: 'Transparence',
        Legal_desc: 'Légal',
        Compliance_desc: 'Conformité',
        Policy_desc: 'Politique de confidentialité',
        Company_desc: 'Société',
        About_Us_desc: 'À propos de nous',
        Social_Media_desc: 'Réseaux sociaux',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Divulgations importantes:',
        Limited_desc: 'FD121 Limited est une société de technologie financière, pas une banque. Les services de garde des réserves sont fournis par ',
        First_desc: "First Digital Trust Limited",
        product_desc: "Tous les noms de produits et d'entreprises sont des marques de commerce™ ou des marques déposées® de leurs détenteurs respectifs. Tous les noms de sociétés, de produits et de services utilisés sur ce site Web sont uniquement à des fins d'identification. L'utilisation de ces noms, marques de commerce et marques ne sous-entend aucune affiliation ou approbation de leur part. ",
        additional_desc: 'Pour des risques, divulgations et informations importants supplémentaires, veuillez visiter',
        Copyright_desc: 'Copyright © 2024 FD121 Limited. Tous droits réservés.'
    },
    modal: {
        hold_desc: 'Si vous détenez',
        Smart_desc: 'du BNB Smart Chain, vous recevrez une récompense bonus,',
        Rewards_desc: 'Les récompenses ne sont disponibles que pour les montants supérieurs à',
        address_desc: "L'adresse détient",
        hours_desc: "pendant 24 heures, et la détention minimale est basée sur le cliché aléatoire du système. Le montant est attribué une fois toutes les 24 heures. L'allocation réelle sur le compte prévaudra.",
    },
    UserInfo: {
        Wallets_desc: 'Portefeuilles',
        Reward_desc: 'Récompense',
        half_month: '15 jours',
        one_month: '1 mois',
        three_month: '3 mois',
        Invitees: 'Invités',
        no_data: 'Aucune donnée',
        walletNo_support: 'Votre portefeuille n\'est pas pris en charge. Veuillez utiliser une autre application de portefeuille.',
        network_wrong: 'Veuillez sélectionner le réseau correct',
      },
}
export default fr_FR

// Datajson:{
//     language_json:{
//         zh_json:'Le chinois simplifié',
//         zh_FT_json: 'Chinois traditionnel',
//         eh_json:'En anglais',
//         ja_JP_json:'japonais',  
//         ko_KR_json:'coréen' ,
//         en_TH_json:'Le thaï',
//         fr_FR_json:'En français',
//         ru_RU_json:'Le russe',
//         en_IN_json:'Langue hindi',
//         en_ID_json:'Langue indonésienne',
//         en_BS_json:'Persan persan',
//         en_YN_json:'Langue vietnamienne',
//         en_ALB_json:'Langue arabe',
//         en_TEQ_json:'La langue turque',
//         en_ES_json:'Langue espagnole',
//         en_PTY_json:'En portugais',
//         en_YDL_json:'Langue italienne'
//     },
// },