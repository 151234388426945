const en_PTY = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'idioma',
        fduse_desc: 'FDUSD é uma Stablecoin respaldada 1:1 pelo dólar americano.',
        introduce_desc: 'Revolutionize as finanças globais com uma Stablecoin digital de ponta, proporcionando segurança, velocidade e inovação a empresas e desenvolvedores em todo o mundo.',
        getfduse_desc: 'Obter FDUSD',
        stability_desc: 'Estabilidade Encontra Inovação',
        experience_desc: 'Experimente velocidades ultrarrápidas e custos mínimos. Molde o futuro das finanças com nossa solução inovadora e confiável.',
        whitepaper_desc: 'Whitepaper',
        redeemable_desc: 'Resgatável 1:1 por dólares americanos',
        dollars_desc: 'O FDUSD destina-se a ser totalmente respaldado por dinheiro e ativos equivalentes em dinheiro. Os tokens destinam-se a serem resgatados 1:1 por dólares americanos.',
        backed_desc: 'Respaldado por ativos totalmente reservados',
        deserved_desc: 'Os ativos reservados são mantidos em estruturas de custódia totalmente segregadas e à prova de falências.',
        programmable_desc: 'Totalmente Programável',
        innovative_desc: 'Com nossa solução inovadora de stablecoin - respaldada por tecnologia avançada, nosso objetivo é fornecer uma alternativa segura e confiável que traga tranquilidade, causando um impacto positivo no mundo das finanças.',
        settle_desc: 'Liquidar em minutos',
        nearZero_desc: 'Custo próximo de zero',
        always_desc: 'Sempre ativo, 24/7',
        fully_desc: 'Totalmente programável',
        rewrite_desc: 'Reescreva o Futuro com a First Digital Labs',
        combining_desc: 'Combinando estabilidade com inovação na First Digital Labs, estamos comprometidos em conquistar confiança oferecendo transparência, conformidade e segurança inabaláveis, respaldadas por tecnologia de ponta.',
        mission_desc: 'Estamos em uma missão para impulsionar a indústria financeira para uma nova era das finanças e remodelar o cenário financeiro global.',
        should_desc: 'Por que você deve usar o First Digital USD?',
        collateral_desc: 'A garantia é protegida por um custodiante qualificado de alto nível com uma licença fiduciária para proteção contínua dos ativos e conformidade regulatória.',
        transparent_desc: 'Transparente',
        independent_desc: 'Atestação independente - a garantia é validada por uma terceira parte independente, garantindo a integridade das reservas e relatórios financeiros.',
        examined_by_desc: 'Examinado por',
        attestation_Reports_desc: 'Relatórios de Atestação',
        January2024_desc: 'Janeiro de 2024',
        Report_desc: 'Relatório',
        December_desc: 'Dezembro de 2023',
        November_desc: 'Novembro de 2023',
        Networks_desc: 'Quais redes são suportadas?',
        available_desc: 'O FDUSD está disponível em',
        Ethereum_desc: 'Ethereum',
        and_desc: 'e',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'com suporte planejado para um número crescente de blockchains.',
        Audited_desc: 'Auditado por',
        Frequently_desc: 'Perguntas Frequentes',
        How_FDUSD_desc: 'Como posso obter FDUSD?',
        Acquiring_desc: 'Aquisição de FDUSD pode ser feita de várias maneiras. Se você é um jogador-chave da indústria, um intermediário financeiro ou um investidor profissional de determinado estatuto e atende aos critérios necessários, você pode comprar diretamente FDUSD da First Digital Labs. Observe que a First Digital Labs não vende tokens diretamente para clientes de varejo.:',
        canstill_desc: 'Você ainda pode obter FDUSD através de mercados secundários sem se tornar um cliente da First Digital Labs. Muitas das principais exchanges de criptomoedas listam FDUSD e oferecem liquidez substancial. Isso proporciona uma maneira acessível e conveniente para que pessoas interessadas adquiram FDUSD. No entanto, é sempre essencial realizar pesquisas minuciosas e garantir a credibilidade da exchange que você está usando.',
        equivalent_desc: 'Como posso confiar que o FDUSD sempre terá o valor equivalente a um dólar?',
        stablecoin_desc: 'Sua confiança no valor estável do FDUSD é bem fundamentada. Cada FDUSD destina-se a ser totalmente respaldadopor um dólar dos Estados Unidos ou um ativo de valor justo equivalente. Esses ativos são mantidos com segurança por um custodiante qualificado em contas de instituições depositárias regulamentadas, proporcionando uma base sólida para o valor da moeda. Esse sistema é o que garante a estabilidade intrínseca do FDUSD e por isso você pode confiar em seu valor equivalente ao dólar dos Estados Unidos.',
        redeem_FDUSD: 'Como posso resgatar o FDUSD?',
        stablecoin_desc: 'Para resgatar sua stablecoin FDUSD, você primeiro precisa se tornar cliente da First Digital Labs e atender a requisitos específicos, incluindo verificações de Anti-Lavagem de Dinheiro (AML) e de Combate ao Financiamento do Terrorismo (CFT). Após a conclusão bem-sucedida dessas verificações, você pode trocar seus FDUSD por seu equivalente em moeda fiduciária, retirando-o de circulação. Alternativamente, você pode vender seus tokens FDUSD no mercado secundário por meio de uma exchange de criptomoedas ou um provedor Over-the-Counter (OTC) que ofereça suporte às stablecoins FD121. Esteja ciente de todas as regulamentações legais e financeiras em sua jurisdição antes de prosseguir.',
        Explore_desc: "Explore nosso Ecossistema",
        Experience_desc: 'Experimente a estabilidade com nossa solução confiável de stablecoin.',
        slide_desc: 'slide',
        _1to6_desc: '1 a 6',
        of7_desc: 'de 7',
        Transparency_desc: 'Transparência',
        Legal_desc: 'Legal',
        Compliance_desc: 'Conformidade',
        Policy_desc: 'Política de Privacidade',
        Company_desc: 'Empresa',
        About_Us_desc: 'Sobre Nós',
        Social_Media_desc: 'Mídias Sociais',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Divulgações Importantes:',
        Limited_desc: 'FD121 Limited é uma empresa de tecnologia financeira, não um banco. Os serviços de custódia dos ativos são fornecidos por',
        First_desc: "First Digital Trust Limited",
        product_desc: 'Todos os nomes de produtos e empresas são marcas comerciais™ ou marcas comerciais registradas® de seus respectivos titulares. Todos os nomes de empresas, produtos e serviços usados neste site são apenas para fins de identificação. O uso desses nomes, marcas comerciais e marcas não implica nenhuma afiliação ou endosso por parte deles.',
        additional_desc: 'Para riscos, divulgações e informações importantes adicionais, visite',
        Copyright_desc: 'Direitos Autorais © 2024 FD121 Limited. Todos os direitos reservados.'
    },
    modal: {
        hold_desc: 'Se você possuir',
        Smart_desc: 'da BNB Smart Chain, você receberá uma recompensa de bônus,',
        Rewards_desc: 'As recompensas estão disponíveis apenas para valores superiores a',
        address_desc: 'O endereço possui',
        hours_desc: 'por 24 horas, e a quantidade mínima é baseada no snapshot aleatório do sistema. O valor é alocado, uma vez a cada 24 horas. A alocação real para a conta prevalecerá.'
    },
    UserInfo: {
        Wallets_desc: 'Carteiras',
        Reward_desc: 'Recompensa',
        half_month: '15 Dias',
        one_month: '1 mês',
        three_month: '3 meses',
        Invitees: 'Convidados',
        no_data: 'Sem Dados',
        walletNo_support: 'Sua carteira não é suportada. Por favor, use outro aplicativo de carteira.',
        network_wrong: 'Por favor, selecione a rede correta',
      },
}
export default en_PTY

// Datajson:{
//     language_json:{
//         zh_json:'Chinês simplificado',
//         zh_FT_json: 'Chinês tradicional',
//         eh_json:'Inglês',
//         ja_JP_json:'Japonês',  
//         ko_KR_json:'Coreano' ,
//         en_TH_json:'Tailandês',
//         fr_FR_json:'Francês',
//         ru_RU_json:'Russo',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonésio',
//         en_BS_json:'Persa',
//         en_YN_json:'Vietnamita',
//         en_ALB_json:'Árabe',
//         en_TEQ_json:'Turco',
//         en_ES_json:'Espanhol',
//         en_PTY_json:'Português',
//         en_YDL_json:'Italiano'
//     },
// },