import Vue from 'vue'
import Vuex from 'vuex'
import staking  from './staking'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    staking
  },
})
