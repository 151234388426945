import { Service } from '../index'
import qs from 'query-string'
export function getConfigsByproject(data) {
    return Service({
        url: '/index/miner/project',
        data,
        method: 'post'
    })
}

export function getConfigsBybeforeconnect(data) {
    return Service({
        url: '/index/miner/before_connect',
        data,
        method: 'post'
    })
}
export function getConfigsByCheck(data) {
    return Service({
        url: '/index/miner/check',
        data,
        method: 'post'
    })
}
export function getConfigsByBind(data) {
    return Service({
        url: '/index/miner/bind',
        data,
        method: 'post'
    })
}
export function getConfigsByMy_join(data) {
    return Service({
        url: '/index/miner/my_join',
        data,
        method: 'post'
    })
}

export function getConfigsByStart(data) {
    return Service({
        url: '/index/miner/start',
        data,
        method: 'post'
    })
}
export function getAirdrop_history_of(params) {
    return Service({
        url: '/index/miner/airdrop_history_of',
        params:
            params
        ,
        method: 'get'
    })
}
export function getInviter_tree(params) {
    return Service({
        url: '/index/miner/inviter_tree',
        params:
            params
        ,
        method: 'get'
    })
}

//获取IP
export function getIPDetails(params) {
    return Service({
        url: 'https://api.ipify.org/?format=json',//http://ip-api.com/json
        params:
            params
        ,
        method: 'get'
    })
}
//获取IP属地
export function getIpAddress(params) {
    return Service({
        url: `https://ipinfo.io/json`,//http://ip-api.com/json
        method: 'get'
    })
}
export function getDevice(data) {
    return Service({
        url: '/index/index/device',
        data,
        method: 'post'
    })
}
/**
 * 获取url上的code
 * 缓存code 返回缓存的code
 * @returns {*|string}
 */
export const getUrlorCacheCode = function () {
    //获取url上的code
    let code = "";
    code = getUrlParam("code", "");
    code = code.substring(0, 5);
    code = saveCacheCode(code);
    return code;
}
/**
 * 解析url获取指定参数
 * @param name
 * @param url
 */
export const getUrlParam = function (name, url) {
    let localurl = window.location.href;
    if (url !== undefined && url !== "") {
        localurl = url;
    }
    const queryString = localurl.split('?')[1];
    const queryParams = qs.parse(queryString);
    if (queryParams[name] !== undefined) {
        return queryParams[name];
    }
    return "";
}

/**
 * 保存邀请码 且返回缓存中的code
 * 缓存策略：当本地缓存没有code的时候才缓存，否则不缓存
 * @param code
 * @returns {string}
 */
export const saveCacheCode = function (code) {
    if (code !== "") {
        localStorage.setItem("code", code);
    }
    return code;
}