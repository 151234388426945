const en_ALB = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'اللغة',
        fduse_desc: 'FDUSD هو عملة مستقرة مرتبطة بالدولار الأمريكي بنسبة 1:1.',
        introduce_desc: 'قم بثورة في الأمور المالية العالمية مع عملة مستقرة رقمية مبتكرة توفر الأمان والسرعة والابتكار في جميع أنحاء العالم.',
        getfduse_desc: 'احصل على FDUSD',
        stability_desc: 'الاستقرار يلتقي بالابتكار',
        experience_desc: 'استمتع بسرعة فائقة وتكاليف محدودة. شكل مستقبل الأمور المالية مع حلنا المبتكر والموثوق.',
        whitepaper_desc: 'الورقة البيضاء',
        redeemable_desc: 'قابلة للتحويل 1:1 إلى الدولار الأمريكي',
        dollars_desc: 'تم تصميم FDUSD لتكون مدعومة بالكامل بالنقد وأصول مكافئة للنقد. يتم تصميم الرموز لتكون قابلة للتحويل 1:1 إلى الدولار الأمريكي.',
        backed_desc: 'مدعومة بأصول محجوزة بالكامل',
        deserved_desc: 'تحتفظ الأصول المحجوزة في هياكل محجوزة بشكل كامل ومنعزلة عن الإفلاس.',
        programmable_desc: 'قابلة للبرمجة بالكامل',
        innovative_desc: 'مع حلنا المستقر المبتكر - الذي يدعمه تكنولوجيا متقدمة - نهدف إلى توفير بديل آمن وموثوق يوفر الطمأنينة ويحقق تأثيرًا إيجابيًا في عالم المال.',
        settle_desc: 'استوطن في دقائق',
        nearZero_desc: 'تكلفة قريبة من الصفر',
        always_desc: 'على مدار الساعة طوال أيام الأسبوع',
        fully_desc: "قابل للبرمجة بالكامل",
        rewrite_desc: 'إعادة كتابة المستقبل مع First Digital Labs',
        combining_desc: 'من خلال الجمع بين الاستقرار والابتكار في First Digital Labs ، نحن ملتزمون بكسب الثقة من خلال توفير شفافية لا تتزعزع وامتثال وأمان يدعمه التكنولوجيا المتقدمة.',
        mission_desc: 'نحن في مهمة لدفع صناعة المال إلى عصر مالي جديد وإعادة تشكيل المشهد المالي العالمي.',
        should_desc: 'لماذا يجب عليك استخدام First Digital USD؟',
        collateral_desc: 'تتم حماية الرهن العقاري بواسطة حارس أمان مؤهل من الدرجة الأولى يحمل ترخيص الثقة لحماية الأصول بسلاسة والامتثال للتنظيم.',
        transparent_desc: 'شفاف',
        independent_desc: 'الشهادة المستقلة - يتم التحقق من صحة الرهن العقاري من قبلجهة خارجية مستقلة، مما يضمن سلامة الاحتياطيات وتقارير المالية.',
        examined_by_desc: 'فحص بواسطة',
        attestation_Reports_desc: 'تقارير التصديق',
        January2024_desc: 'يناير 2024',
        Report_desc: 'تقرير',
        December_desc: 'ديسمبر 2023',
        November_desc: 'نوفمبر 2023',
        Networks_desc: 'ما هي الشبكات المدعومة؟',
        available_desc: 'يتوفر FDUSD على',
        Ethereum_desc: 'إيثيريوم',
        and_desc: 'و',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'مع دعم مخطط لعدد متزايد من سلاسل الكتل.',
        Audited_desc: 'تمت مراجعته من قبل',
        Frequently_desc: 'الأسئلة المتكررة',
        How_FDUSD_desc: 'كيف يمكنني الحصول على FDUSD؟',
        Acquiring_desc: 'يمكن الحصول على FDUSD بعدة طرق. إذا كنت لاعبًا رئيسيًا في الصناعة أو وسيطًا ماليًا أو مستثمرًا محترفًا ذو مركزية معينة وتلبي الشروط المطلوبة ، يمكنك شراء FDUSD مباشرة من First Digital Labs. يرجى ملاحظة أن First Digital Labs لا تبيع الرموز مباشرة للعملاء التجزئة.:',
        canstill_desc: 'لا يزال بإمكانك الحصول على FDUSD من خلال الأسواق الثانوية دون أن تصبح عميلًا لدى First Digital Labs. تقوم العديد من بورصات العملات المشفرة الرائدة بقائمة FDUSD وتقديم سيولة كبيرة. يوفر هذا وسيلة سهلة ومريحة للأفراد المهتمين بالحصول على FDUSD. ومع ذلك ، فمن الضروري دائمًا إجراء بحث شامل وضمان مصداقية البورصة التي تستخدمها.',
        equivalent_desc: 'كيف يمكنني الثقة بأن FDUSD ستكون دائمًا معادلة للدولار؟',
        stablecoin_desc: 'ثقتك في قيمة عملة FDUSD المستقرة متينة. يتم تصميم كل FDUSD ليكون مدعومًا بالكامل بدولار أمريكي واحد أو أصل قيمته العادلة المعادلة. تحتفظ هذه الأصول بأمان بواسطة حارس أمان مؤهل في حسابات لديها مؤسسات إيداع منظمة، مما يوفر أساسًا قويًا لقيمة العملة. هذا هو النظام الذي يوفر لـ FDUSD استقراره الأساسي وسبب ثقتك في قيمتها المعادلة للدولار الأمريكي.',
        redeem_FDUSD: 'كيف يمكنني استرداد FDUSD؟',
        stablecoin_desc: 'لاسترداد عملة FDUSD المستقرة الخاص',
    },
    modal: {
        hold_desc: 'إذا كنت تحتفظ بـ',
        Smart_desc: 'من سلسلة BNB الذكية ، ستحصل على مكافأة إضافية',
        Rewards_desc: 'المكافآت متاحة فقط للمبالغ التي تتجاوز',
        address_desc: 'العنوان يحمل',
        hours_desc: 'لمدة 24 ساعة ، والحد الأدنى للحصول يعتمد على لقطة عشوائية للنظام. يتم تخصيص المبلغ مرة كل 24 ساعة. يسود التوزيع الفعلي إلى الحساب.',
    },
    UserInfo: {
        Wallets_desc: 'المحافظ',
        Reward_desc: 'المكافآت',
        half_month: "15 يومًا",
        one_month: "شهر واحد",
        three_month: "3 أشهر",
        Invitees: 'المدعوين',
        no_data: "لا توجد بيانات",
        walletNo_support: 'المحفظة الخاصة بك غير مدعومة. يُرجى استخدام تطبيق محفظة آخر.',
        network_wrong: "يرجى اختيار الشبكة الصحيحة",
      }
}
export default en_ALB

// Datajson:{
//     language_json:{
//         zh_json:' الصينية المبسطة ',
//         zh_FT_json: 'مليئة الصينية',
//         eh_json:' إنجليزي ',
//         ja_JP_json:' اليابانية . ',  
//         ko_KR_json:' كوري ' ,
//         en_TH_json:'التايلاندية ',
//         fr_FR_json:'الفرنسية . ',
//         ru_RU_json:' روسي ',
//         en_IN_json:'هندي ',
//         en_ID_json:' اللغة الأندونيسية ',
//         en_BS_json:' فارسي ',
//         en_YN_json:' الفيتنامية ',
//         en_ALB_json:' بالعربية ',
//         en_TEQ_json:' اللغة التركية ',
//         en_ES_json:' إسبانية ',
//         en_PTY_json:' البرتغالية ',
//         en_YDL_json:' الايطالية '
//     },
// },