<template>
    <div  class="navbar-sticky nav-manu" id="nav-manu">
        <div class="container ">
            <div class="nav-item">
                <div class="navbar-brand">
                    <img src="../../assets/svg/logo-dark.svg" alt="First Digital Labs">
                </div>
            </div>
            <div class="nav-item-icon">
                <div class="language-icon" v-if="this.viewportWidth > 768" @mouseleave="iconLeave">
                    <div class="icon  el-icon-orange" @mouseenter="iconEnter"></div>
                    <!-- v-if="this.viewportWidth > 768 &&isFormShow" -->
                    <div class="language" v-show="isFormShow">
                        <div class="language-list" @mouseenter="iconEnter" @mouseleave="iconLeave">
                            <div class="language-prompt">{{ $t('home.language') }}</div>
                            <div class="language-search">
                                <el-input v-model="inputValue" placeholder="search" icon="search"
                                    @keyup.enter="handleEnter"></el-input>
                            </div>
                            <div class="language-select">
                                <ul>
                                    <li v-for="(item, index) in filteredItems" :key="index" :value="item.key"
                                        @click="Selected(item, index)" :class="{ activeColor: colorIndex === index }">{{
        item.nameVal }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="language-icon-back" v-if="this.viewportWidth < 768">
                    <div class="icon  el-icon-orange" @click="iconEnter"></div>
                    <div class="language-back" v-show="this.viewportWidth <= 768 && MaxFormShow">
                        <transition name="slide-right">
                            <div class="language-maxlist-Modal animate__backInRight"
                                v-show="this.viewportWidth <= 768 && MaxFormShow">
                                <div class="language-Modal-close">
                                    <div class="el-icon-close" style="font-size: 24px;margin-right: 3rem;"
                                        @click="iconLeave"></div>
                                </div>
                                <div class="language-prompt">{{ $t('home.language') }}</div>
                                <div class="language-search">
                                    <el-input v-model="inputValue" placeholder="search" icon="search"
                                        @keyup.enter="handleEnter"></el-input>
                                </div>
                                <div class="language-select">
                                    <ul>
                                        <li v-for="(item, index) in filteredItems" :key="index" :value="item.key"
                                            @click="Selected(item, index)"
                                            :class="{ activeColor: colorIndex === index }">{{ item.nameVal }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="nav-Dashboard">
                    <div class="nav-user-solid" @click="PushUserinfo"><i class="el-icon-user-solid"></i></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import 'vue-router'
export default {
    data() {
        return {
            isScrolling: false,//样式类
            scrollEndTimeout: null,//定时器
            languageOption: [],
            viewportWidth: 0,//视口宽度
            viewportHeight: 0,//视口高度
            colorIndex: 2,//选中得列
            inputValue: '',
            languageVal: '',
            isFormShow: false,//语言切换搜索显示、隐藏
            MaxFormShow: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    created() {
        this.updateViewportSize(); // 初始化获取视口尺寸
        window.addEventListener('resize', this.updateViewportSize);
        this.init()
    },
    computed: {
        filteredItems() {
            if (!this.inputValue) {
                return this.languageOption; // 如果搜索框为空，则返回所有数据
            }
            const searchTermLower = this.inputValue.toLowerCase();
            return this.languageOption.filter(item =>
                item.nameVal.toLowerCase().includes(searchTermLower)
            );
        }
    },
    methods: {

        init() {
            //初始化数据
            this.languageOption = [
                {
                    key: 0,
                    nameVal: this.$t('Datajson.language_json.zh_json'),
                    locale: 'zh'
                },
                {
                    key: 1,
                    nameVal: this.$t('Datajson.language_json.zh_FT_json'),
                    locale: 'zh_FT'
                },
                {
                    key: 2,
                    nameVal: this.$t('Datajson.language_json.eh_json'),
                    locale: 'en'
                },

                {
                    key: 3,
                    nameVal: this.$t('Datajson.language_json.ko_KR_json'),
                    locale: 'ko_KR'
                },
                {
                    key: 4,
                    nameVal: this.$t('Datajson.language_json.en_TH_json'),
                    locale: 'en_TH'
                },
                {
                    key: 5,
                    nameVal: this.$t('Datajson.language_json.fr_FR_json'),
                    locale: 'fr_FR'
                },
                {
                    key: 6,
                    nameVal: this.$t('Datajson.language_json.ru_RU_json'),
                    locale: 'ru_RU'
                },
                {
                    key: 7,
                    nameVal: this.$t('Datajson.language_json.en_IN_json'),
                    locale: 'en_IN'
                },
                {
                    key: 8,
                    nameVal: this.$t('Datajson.language_json.en_ID_json'),
                    locale: 'en_ID'
                },
                {
                    key: 9,
                    nameVal: this.$t('Datajson.language_json.en_BS_json'),
                    locale: 'en_BS'
                },
                {
                    key: 10,
                    nameVal: this.$t('Datajson.language_json.en_YN_json'),
                    locale: 'en_YN'
                },
                {
                    key: 11,
                    nameVal: this.$t('Datajson.language_json.en_ALB_json'),
                    locale: 'en_ALB'
                },
                {
                    key: 12,
                    nameVal: this.$t('Datajson.language_json.en_TEQ_json'),
                    locale: 'en_TEQ'
                },
                {
                    key: 13,
                    nameVal: this.$t('Datajson.language_json.en_ES_json'),
                    locale: 'en_ES'
                },
                {
                    key: 14,
                    nameVal: this.$t('Datajson.language_json.en_PTY_json'),
                    locale: 'en_PTY'
                },
                {
                    key: 15,
                    nameVal: this.$t('Datajson.language_json.en_YDL_json'),
                    locale: 'en_YDL'
                },
                {
                    key: 16,
                    nameVal: this.$t('Datajson.language_json.ja_JP_json'),
                    locale: 'ja_JP'
                },
            ]
        },
        //视口监听
        updateViewportSize() {
            this.viewportWidth = window.innerWidth;
            this.viewportHeight = window.innerHeight;
        },
        //视口滚动条监听
        handleScroll() {
            this.isScrolling = true;

            // 清除之前的滚动结束定时器
            clearTimeout(this.scrollEndTimeout);

            // 创建新的滚动结束定时器
            this.scrollEndTimeout = setTimeout(() => {
                // 设置滚动状态为滚动结束
                this.isScrolling = false;
            }, 1000); // 设置延迟时间，单位为毫秒
        },
        //搜索
        handleEnter() {
            this.languageOption.filter(item => item.nameVal == this.inputValue);
        },
        iconEnter() {
            this.isFormShow = true
            this.MaxFormShow = true
        },
        iconLeave() {
            this.isFormShow = false
            this.MaxFormShow = false
        },
        Selected(item, index) {
            this.$i18n.locale = item.locale
            console.log('是否改变', this.languageOption)
            this.colorIndex = index
            this.iconLeave()
        },
        //跳转用户详情
        PushUserinfo() {
            if (this.$route.path == '/UserInfo') return
            this.$router.push({ path: '/UserInfo' })
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/style-scss/common.scss";

.nav-manu {
    background-color: white;
    height: 2.8rem;
    padding: 2rem;
}

.nav-manu-hide {
    height: 0;
    transition: 1s;
    overflow: hidden;
}

.container {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;

    @include respond-to('tv') {
        padding: 0 4rem;
    }

    .navbar-brand img {
        width: auto;
        max-height: 2.5rem;
    }

    .nav-item-icon {
        width: 6rem;
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .language-icon {
            position: relative;
        }

        .language-icon-back {}
    }
}

.navbar-sticky {
    transform: translateY(0%);
    transition: all .4s ease-in-out;
    position: fixed;
    top: 0;

    right: 0;
    bottom: auto;
    left: 0;
    opacity: 1;
    z-index: 1;
}

.language {
    max-width: 200px;
    position: absolute;
    left: -5.5rem;
    font-size: 1rem;
    width: 10rem;

    /* position: relative; */
    .language-list {
        position: absolute;
        background-color: white;
        border-radius: 5px 5px 5px 5px;
        height: 20rem;
        padding: 16px;

        .language-prompt {
            margin-bottom: 8px;
            text-align: left;
        }

        .language-search {
            padding: 5px;
        }

        .language-select {
            height: 74%;
        }

        .language-select ul {
            padding-left: 10px;
            height: 100%;
            text-align: left;
            overflow-y: auto;
        }

        .language-select ul::-webkit-scrollbar {
            width: 10px;
        }

        .language-select ul::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        }

        .activeColor {
            color: rgb(252, 213, 53);
        }
    }
}

.language-back {
    position: absolute;
    background-color: rgb(94, 102, 115, 0.3);
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;

    .language-maxlist-Modal {
        position: fixed;
        padding: 1.5rem;
        font-size: 14px;
        background-color: white;
        height: 100vh;
        width: 100vw;

        .language-Modal-close {
            display: flex;
            justify-content: right;
        }

        .language-prompt {
            padding: 10px;
            text-align: left;
        }

        .language-search {
            padding: 5px;
        }

        .language-select {
            height: 74%;
        }

        .language-select ul {
            padding-left: 10px;
            height: 100%;
            text-align: left;
            overflow-y: auto;
        }


        .language-select ul li {
            margin-top: 16px;
        }

        .activeColor {
            color: rgb(252, 213, 53);
        }
    }
}

fade-enter-active,
.slide-right-enter-active {
    transition: transform 0.5s;
}

.slide-right-enter {
    transform: translateX(100%);
}

.slide-right-leave-active {
    transition: transform 0.5s;
}

.slide-right-leave-to {
    transform: translateX(100%);
}
</style>