import Vue from 'vue'
import VueRouter from 'vue-router'
import pags from './page'

const routes = [...pags]

Vue.use(VueRouter)


const router = new VueRouter({
  routes
})

export default router
