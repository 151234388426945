//日语
const ja_JP = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language:'言語',
        fduse_desc: 'FDUSDは1:1の米ドルに裏付けられたステーブルコインです。',
        introduce_desc: '革新的なデジタルステーブルコインによって、ビジネスとビルダーにセキュリティ、スピード、革新を提供し、世界中で金融を革新します。',
        getfduse_desc: 'FDUSDを入手する',
        stability_desc: '安定性とイノベーション',
        experience_desc: '高速かつ低コストな取引を体験してください。革新的で信頼性の高いソリューションで、金融の未来を形作りましょう。',
        whitepaper_desc: 'ホワイトペーパー',
        redeemable_desc: '1:1で米ドルに交換可能',
        dollars_desc: 'FDUSDは現金および現金同等の資産に完全に裏付けられることを目指しています。トークンは1:1で米ドルに交換可能です。',
        backed_desc: '完全に準備された資産に裏付けられています',
        deserved_desc: '準備された資産は、破産から隔離された保全構造に保持されています。',
        programmable_desc: '完全にプログラム可能',
        innovative_desc: '革新的なステーブルコインソリューションにより、先進技術に裏打ちされた安全で信頼性の高い選択肢を提供し、金融界にポジティブな影響を与えることを目指しています。',
        settle_desc: '数分で決済',
        nearZero_desc: 'ほぼゼロのコスト',
        always_desc: '常に稼働、24時間365日',
        fully_desc: "完全にプログラム可能",
        rewrite_desc: 'First Digital Labsで未来を書き換える',
        combining_desc: 'First Digital Labsでは安定性と革新を融合し、徹底的な透明性、コンプライアンス、セキュリティを提供することで信頼を獲得することに取り組んでいます。',
        mission_desc: '私たちは金融業界を新たな時代に推進し、世界の金融ランドスケープを再構築する使命を持っています。',
        should_desc: 'なぜFirst Digital USDを使用する必要があるのですか？',
        collateral_desc: '担保はトップクラスの信託ライセンスを持つ信頼性のある保管業者によって保護されています。',
        transparent_desc: '透明性',
        independent_desc: '独立した監査-担保は独立した第三者によって検証され、準備金および財務報告の信頼性が保証されています。',
        examined_by_desc: '監査：',
        attestation_Reports_desc: '監査報告書',
        January2024_desc: '2024年1月',
        Report_desc: '報告書',
        December_desc: '2023年12月',
        November_desc: '2023年11月',
        Networks_desc: '対応しているネットワークは？',
        available_desc: 'FDUSDは次のネットワークで利用可能です：',
        Ethereum_desc: 'Ethereum',
        and_desc: 'および',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: '今後、さらに多くのブロックチェーンをサポートする予定です。',
        Audited_desc: '監査：',
        Frequently_desc: 'よくある質問',
        How_FDUSD_desc: 'FDUSDを入手する方法は？',
        Acquiring_desc: 'FDUSDを入手する方法はいくつかあります。業界の主要プレーヤーや金融機関、一定の地位を持つプロフェッショナル投資家である場合、First Digital Labsから直接FDUSDを購入することができます。ただし、First Digital Labsは一般の個人顧客にトークンを直接販売していません。',
        canstill_desc: 'First Digital Labsのクライアントにならずに、二次市場でFDUSDを入手することもできます。多くの主要な仮想通貨取引所がFDUSDをリストしており、流動性が豊富です。これにより、興味のある個人がFDUSDを取得するためのアクセスしやすく便利な方法が提供されています。ただし、利用する取引所の信頼性を徹底的に調査し、確認することが常に重要です。',
        equivalent_desc: 'FDUSDが常に1ドルと同等であることを信頼するにはどうすればよいですか？',
        stablecoin_desc: 'FDUSDステーブルコインの価値に対する信頼は、十分に根拠があります。各FDUSDは、1米ドルまたは同等の公正な価値の資産に完全に裏付けられることを意図しています。これらの資産は、信託ライセンスを持つ信頼性のある保管業者によって安全に保持され、規制された預金機関の口座に保管されています。これにより、FDUSDの価値は確固たる基盤が提供されており、米ドルと同等の価値に信頼性があります。',
        redeem_FDUSD: 'FDUSDを交換するにはどうすればよいですか？',
        stablecoin_desc: 'FDUSDステーブルコインを交換するには、まずFirst Digital Labsのクライアントになり、特定の要件を満たす必要があります。これには、マネーロンダリング防止（AML）およびテロ資金供与防止（CTF）のチェックなどが含まれます。これらのチェックを成功裏に完了すると、FDUSDを法定通貨と交換することができ、それによってFDUSDを流通から外すことができます。また、FDUSDトークンをサポートする仮想通貨取引所やカウンター取引（OTC）プロバイダーを介して二次市場でFDUSDトークンを売却することもできます。手続きを進める前に、所在地のすべての法的および金融規制について認識してください。',
        Explore_desc: "エコシステムを探索する",
        Experience_desc: '信頼性のあるステーブルコインソリューションで安定性を体験してください。',
        slide_desc: 'スライド',
        _1to6_desc: '1から6まで',
        of7_desc: '全7つ中',
        Transparency_desc: '透明性',
        Legal_desc: '法的',
        Compliance_desc: 'コンプライアンス',
        Policy_desc: 'プライバシーポリシー',
        Company_desc: '会社',
        About_Us_desc: '私たちについて',
        Social_Media_desc: 'ソーシャルメディア',
        Twitter_desc: 'Twitter',
        Disclosures_desc: '重要な開示事項：',
        Limited_desc: 'FD121 Limitedは金融テクノ',
    },
    modal: {
        hold_desc: '保有している場合、',
        Smart_desc: 'BNB Smart Chainの場合、ボーナスリワードが付与されます。',
        Rewards_desc: 'リワードは、',
        address_desc: 'そのアドレスには',
        hours_desc: '24時間保持し、最小保有額はシステムのランダムスナップショットに基づいています。金額は24時間ごとに割り当てられます。実際のアカウントへの割り当てが優先されます。'
    },
    UserInfo: {
        Wallets_desc: 'ウォレット',
        Reward_desc: '報酬',
        half_month: '15日間',
        one_month: '1か月',
        three_month: '3か月',
        Invitees: '招待者',
        no_data: 'データなし',
        walletNo_support: 'お使いのウォレットはサポートされていません。別のウォレットアプリを使用してください。',
        network_wrong: '正しいネットワークを選択してください',
      },
}
export default ja_JP

// Datajson:{
//     language_json:{
//         zh_json:'簡体字中国語',
//         zh_FT_json: '繁体字中国語',
//         eh_json:'英語です',
//         ja_JP_json:'日本語.',  
//         ko_KR_json:'韓国語です' ,
//         en_TH_json:'タイ語です',
//         fr_FR_json:'フランス語です',
//         ru_RU_json:'ロシア語です',
//         en_IN_json:'ヒンディー語',
//         en_ID_json:'インドネシア語',
//         en_BS_json:'ペルシャ語',
//         en_YN_json:'ベトナム語です',
//         en_ALB_json:'アラビア語です',
//         en_TEQ_json:'トルコ語',
//         en_ES_json:'スペイン語です',
//         en_PTY_json:'ポルトガル語',
//         en_YDL_json:'イタリア語です'
//     },
// },