<template>
    <div class="modal-bg" v-show="isShow">
        <div class="modal-container">
            <div class="modal-top">
                <span @click="hideModal"><img class="close-modal-svg" src="../../assets/svg/x_icon.svg" /></span>
            </div>
            <div class="modal-header">
                <slot name="header"></slot>
            </div>
            <div class="modal-main">
                <slot name="main"></slot>
            </div>
            <div class="modal-footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script>
import VueQr from 'vue-qr';
export default {
    name: 'Modal',
    props: {
        isShow: {
            type: Boolean,
            default: true,
        },
        title: {
            default: ''
        },
        userInfo: {
            qrUrl: 'http://www.google.com',
            // dialogInfo:"Web3.js是一个用于与以太坊网络进行交互的JavaScript库。它提供了一系列的API和工具,用于连接到以太坊网络、管理账户、发送交易、调用智能合约等操作。",
            // imageUrl:require('../assets/svg/fdusd-card-thumb.png'),
        },
        projectInfo: {}
    },
    components: {
        'vue-qr': VueQr,
    },
    methods: {
        submit() {
            this.$emit('submit')
        },
        hideModal() {
            this.$emit('close')
        },
        copylink() {
            const textArea = document.createElement("textarea");
            textArea.value = this.userInfo.qrUrl;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            this.$message.success('Copied');
        },

    },

}
</script>
<style lang="scss" scoped>
.modal-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .35);
    z-index: 2;
    .modal-container {
        width: 20rem;
        position: fixed;
        overflow: hidden;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 1);
        border-radius: 20px;
        top: 50%;
        left: 50%;
        .modal-top {
            color: #a5a2a2;
            text-align: right;
            margin: 1rem 1rem 0 0;
        }
    }
    .modal-header{
        min-height: 2rem;
    }
    .modal-main{
        min-height: 10rem;
    }
    .modal-footer{
        min-height: 2rem;
    }
    // @media(max-width: 688px) {
    //     .modal-container {
    //         width: calc(100vw/1.2);
    //     }

    //     .modal-body {
    //         height: calc(100vh/4);
    //     }
    // }
    .close-modal-svg {
        cursor: pointer;
        background: white;
    }
}
</style>