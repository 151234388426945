//英语
const en = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    language_join: {
        language_join: 'English',
    },
    home: {
        language: 'language',
        fduse_desc: 'FDUSD is a 1:1 USD-backed Stablecoin.',
        introduce_desc: 'Revolutionize global finance with a cutting-edge digital stablecoin, providing businesses and builders with security, speed, and innovation worldwide.',
        getfduse_desc: 'Get FDUSD',
        stability_desc: 'Stability Meets Innovation',
        experience_desc: 'Experience lightning-fast speeds and minimal costs. Shape the future of finance withour innovative and trusted solution.',
        whitepaper_desc: 'Whitepaper',
        redeemable_desc: 'Redeemable 1:1 for U.S. dollars',
        dollars_desc: 'FDUSD is intended to be a fully backed by cash and cash equivalent assets. Tokens are intended to be redeemable 1:1 for U.S. dollars.',
        backed_desc: 'Backed by fully reserved assets',
        deserved_desc: 'Reserved assets are held in fully segregated bankruptcy-remote holding structures.',
        programmable_desc: 'Fully Programmable',
        innovative_desc: 'With our innovative stablecoin solution - backed by advanced technology, we aim to provide a safe and reliable alternative that brings peace of mind, making a positive impact in the world of finance.',
        settle_desc: 'Settle in minutes',
        nearZero_desc: 'Near-zero Cost',
        always_desc: 'Always-on, 24/7',
        fully_desc: "Fully Programmable",
        rewrite_desc: 'Rewrite Future with First Digital Labs',
        combining_desc: 'Combining stability with innovation at First Digital Labs, we are committed to gaining trust by offering unwavering transparency, compliance and security backed by cutting-edge technology.',
        mission_desc: 'We are on a mission to propel the financial industry into a new era of finance and reshape the global financial landscape.',
        should_desc: 'Why Should You Use First Digital USD?',
        collateral_desc: 'Collateral is safeguarded by a top-tier, qualified custodian with a trust license for seamless asset protection and regulatory compliance.',
        transparent_desc: 'Transparent',
        independent_desc: 'Independent attestation - the collateral is validated by an independent third-party, ensuring the integrity of the reserves and financial reporting.',
        examined_by_desc: 'Examined by',
        attestation_Reports_desc: 'Attestation Reports',
        January2024_desc: 'January 2024',
        Report_desc: 'Report',
        December_desc: 'December 2023',
        November_desc: 'November 2023',
        Networks_desc: 'Which Networks Are Supported?',
        available_desc: 'FDUSD is available on',
        Ethereum_desc: 'Ethereum',
        and_desc: 'and',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'with planned support for an increasing number of blockchains.',
        Audited_desc: 'Audited by',
        Frequently_desc: 'Frequently Asked Questions',
        How_FDUSD_desc: 'How can I get FDUSD?',
        Acquiring_desc: 'Acquiring FDUSD can be achieved in a couple of ways. If you are a key industry player, a financial intermediary, or a professional investor of a certain stature and meet the requisite criteria, you can directly purchase FDUSD from First Digital Labs. Please note that First Digital Labs does not sell tokens directly to retail customers.:',
        canstill_desc: 'You can still obtain FDUSD through secondary markets without becoming a client of First Digital Labs. Many leading cryptocurrency exchanges list FDUSD and offer substantial liquidity. This provides an accessible and convenient way for interested individuals to acquire FDUSD. It' + 's always essential, however, to conduct thorough research and ensure the credibility of the exchange youre using.',
        equivalent_desc: 'How can I trust that FDUSD will always be equivalent to a dollar?',
        stablecoin_desc: 'Your trust in the FDUSD stablecoin value is well-grounded. Each FDUSD is intended to be fully backed by one US dollar or an asset of equivalent fair value. These assets are safely held by aqualified custodian in accounts with regulated depository institutions, providing a solidfoundation for the coin' + 's value. This system is what provides FDUSD with its inherent stabilityand why you can trust its equivalent value to the US dollar.',
        redeem_FDUSD: 'How can I redeem FDUSD?',
        stablecoin_desc: 'To redeem your FDUSD stablecoin, you must first become a client of First Digital Labs and meet specific requirements, including Anti-Money Laundering (AML) and Counter-Terrorism Financing (CTF) checks. Upon successful completion of these checks, you can exchange your FDUSD for its equivalent in fiat currency, thereby taking it out of circulation. Alternatively, you can sell your FDUSD tokens on the secondary market through a cryptocurrency exchange or an Over-the-Counter (OTC) provider that supports FD121' + 's stablecoins. Please be aware of all legal and financial regulations in your jurisdiction before proceeding.',
        Explore_desc: "Explore our Ecosystem",
        Experience_desc: 'Experience the stability with our reliable stablecoin solution.',
        slide_desc: 'slide',
        _1to6_desc: '1 to 6',
        of7_desc: 'of 7',
        Transparency_desc: 'Transparency',
        Legal_desc: 'Legal',
        Compliance_desc: 'Compliance',
        Policy_desc: 'Privacy Policy',
        Company_desc: 'Company',
        About_Us_desc: 'About Us',
        Social_Media_desc: 'Social Media',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Important Disclosures:',
        Limited_desc: 'FD121 Limited is a financial technology company, not a bank. Custody services of the reserves are provided by ',
        First_desc: "First Digital Trust Limited",
        product_desc: 'All product and company names are trademarks™ or registered® trademarks of their respective holders. All company, product and service names used in this website are for identification purposes only. Use of these names, trademarks and brands does not imply any affiliation with or endorsement by them.',
        additional_desc: 'For additional important risks, disclosures, and information, please visit',
        Copyright_desc: 'Copyright © 2024 FD121 Limited. All Rights Reserved.'
    },
    modal: {
        hold_desc: 'If you hold',
        Smart_desc: 'of the BNB Smart Chain, you will have a bonus reward,',
        Rewards_desc: 'Rewards are only available for amounts exceeding',
        address_desc: 'The address holds',
        hours_desc: 'for 24 hours, and the minimum holding is based on the random snapshot of the system. Amount is allocated, once every 24 hours。 The actual allocation to the account shall prevail。',
        copyLink_desc: 'Copy Link '
    },
    UserInfo: {
        Wallets_desc: 'Wallets',
        Reward_desc: 'Reward',
        half_month: "15 Days",
        one_month: "1 month",
        three_month: "3 months",
        Invitees:'Invitees',
        no_data: "No Data",
        walletNo_support:'Your wallet is not supported. Please use another wallet app',
        network_wrong: "Please select the correct network",
    }
}
export default en

// Datajson:{
//     language_json:{
//         zh_json:'Simplified Chinese',
//         zh_FT_json: 'traditional Chinese',
//         eh_json:'English',
//         ja_JP_json:'Japanese',
//         ko_KR_json:'Korean' ,
//         en_TH_json:'Thai',
//         fr_FR_json:'French',
//         ru_RU_json:'Russian',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonesian',
//         en_BS_json:'farsi',
//         en_YN_json:'Vietnamese',
//         en_ALB_json:'Arabic',
//         en_TEQ_json:'Turkish',
//         en_ES_json:'Spanish',
//         en_PTY_json:'Portuguese',
//         en_YDL_json:'Italian'
//     },
// },