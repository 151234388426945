const en_ID = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'bahasa',
        fduse_desc: 'FDUSD adalah Stablecoin yang didukung oleh USD dalam perbandingan 1:1.',
        introduce_desc: 'Revolutionize keuangan global dengan stablecoin digital terdepan, memberikan keamanan, kecepatan, dan inovasi bagi bisnis dan pengembang di seluruh dunia.',
        getfduse_desc: 'Dapatkan FDUSD',
        stability_desc: 'Stabilitas Bertemu Inovasi',
        experience_desc: 'Nikmati kecepatan yang sangat cepat dan biaya minimal. Bentuk masa depan keuangan dengan solusi inovatif dan terpercaya kami.',
        whitepaper_desc: 'Whitepaper',
        redeemable_desc: 'Dapat Ditukarkan 1:1 dengan dolar AS',
        dollars_desc: 'FDUSD dimaksudkan untuk sepenuhnya didukung oleh uang tunai dan aset setara uang tunai. Token dimaksudkan dapat ditukarkan 1:1 dengan dolar AS.',
        backed_desc: 'Didukung oleh aset yang sepenuhnya direservasi',
        deserved_desc: 'Aset yang direservasi disimpan dalam struktur kepemilikan yang terpisah dari kebangkrutan.',
        programmable_desc: 'Sepenuhnya Dapat Diprogram',
        innovative_desc: 'Dengan solusi stablecoin inovatif kami - didukung oleh teknologi canggih, kami bertujuan untuk menyediakan alternatif yang aman dan dapat diandalkan yang memberikan ketenangan pikiran dan memberikan dampak positif di dunia keuangan.',
        settle_desc: 'Selesaikan dalam hitungan menit',
        nearZero_desc: 'Biaya Dekat Nol',
        always_desc: 'Selalu Aktif, 24/7',
        fully_desc: "Sepenuhnya Dapat Diprogram",
        rewrite_desc: 'Menulis Ulang Masa Depan dengan First Digital Labs',
        combining_desc: 'Dengan menggabungkan stabilitas dengan inovasi di First Digital Labs, kami berkomitmen untuk mendapatkan kepercayaan dengan menawarkan transparansi, kepatuhan, dan keamanan yang teguh didukung oleh teknologi terkini.',
        mission_desc: 'Kami berada dalam misi untuk mendorong industri keuangan ke era keuangan baru dan membentuk lanskap keuangan global.',
        should_desc: 'Mengapa Anda Harus Menggunakan First Digital USD?',
        collateral_desc: 'Jaminan dijaga oleh kustodian berperingkat tinggi yang memiliki lisensi kepercayaan untuk perlindungan aset yang mulus dan kepatuhan peraturan.',
        transparent_desc: 'Transparan',
        independent_desc: 'Attestasi independen - jaminan divalidasi oleh pihak ketiga independen, memastikan integritas cadangan dan pelaporan keuangan.',
        examined_by_desc: 'Diperiksa oleh',
        attestation_Reports_desc: 'Laporan Attestasi',
        January2024_desc: 'Januari 2024',
        Report_desc: 'Laporan',
        December_desc: 'Desember 2023',
        November_desc: 'November 2023',
        Networks_desc: 'Jaringan Mana yang Didukung?',
        available_desc: 'FDUSD tersedia di',
        Ethereum_desc: 'Ethereum',
        and_desc: 'dan',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'dengan dukungan yang direncanakan untuk jumlah yang semakin banyak dari blockchain.',
        Audited_desc: 'Diaudit oleh',
        Frequently_desc: 'Pertanyaan yang Sering Diajukan',
        How_FDUSD_desc: 'Bagaimana cara mendapatkan FDUSD?',
        Acquiring_desc: 'Mendapatkan FDUSD dapat dicapai dengan beberapa cara. Jika Anda adalah pemain industri kunci, perantara keuangan, atau investor profesional dengan status tertentu dan memenuhi kriteria yang diperlukan, Anda dapat membeli FDUSD langsung dari First Digital Labs. Harap dicatat bahwa First Digital Labs tidak menjual token secara langsung kepada pelanggan eceran.:',
        canstill_desc: 'Anda masih dapat memperoleh FDUSD melalui pasar sekunder tanpa menjadi klien First Digital Labs. Banyak bursa mata uang kripto terkemuka mencantumkan FDUSD dan menawarkan likuiditas yang substansial. Ini memberikan cara yang mudah dan nyaman bagi individu yang tertarik untuk memperoleh FDUSD. Namun, selalu penting untuk melakukan riset menyeluruh dan memastikan kredibilitas pertukaran yang Anda gunakan.',
        equivalent_desc: 'Bagaimana saya bisa percaya bahwa FDUSD selalu setara dengan satu dolar?',
        stablecoin_desc: 'Kepercayaan Anda pada nilai stablecoin FDUSD sangatlah kuat. Setiap FDUSD dimaksudkan untuk sepenuhnya didukung oleh satu dolar AS atau aset dengan nilai wajar yang setara. Aset-aset ini aman disimpan oleh kustodian yang berkualifikasi dalam rekening dengan lembaga penyimpan teratur, memberikan dasar yang kokoh bagi nilai koin tersebut. Sistem ini adalah yang memberikan stabilitas inheren pada FDUSD dan alasan mengapa Anda dapat mempercayai nilai setara dengan dolar AS.',
        redeem_FDUSD: 'Bagaimana cara menukarkan FDUSD?',
        stablecoin_desc: 'Untuk menukarkan stablecoin FDUSD Anda, Anda harus menjadi klien First Digital Labs terlebih dahulu dan memenuhi persyaratan tertentu, termasuk pemeriksaan Anti-Money Laundering (AML) dan Counter-Terrorism Financing (CTF). Setelah berhasil menyelesaikan pemeriksaan ini, Anda dapat menukar FDUSD Anda dengan mata uang fiat yang setara, sehingga mengeluarkannya dari peredaran. Alternatifnya, Anda dapat menjual token FDUSD Anda di pasar sekunder melalui bursa mata uang kripto atau penyedia Over-the-Counter (OTC) yang mendukung stablecoin FDUSD. Harap perhatikan semua regulasi hukum dan keuangan di yurisdiksi Anda sebelum melanjutkan.',
        Explore_desc: "Jelajahi Ekosistem Kami",
        Experience_desc: 'Rasakan stabilitas dengan solusi stablecoin yang dapat diandalkan kami.',
        slide_desc: 'slide',
        _1to6_desc: '1 hingga 6',
        of7_desc: 'dari 7',
        Transparency_desc: 'Transparansi',
        Legal_desc: 'Hukum',
        Compliance_desc: 'Kepatuhan',
        Policy_desc: 'Kebijakan Privasi',
        Company_desc: 'Perusahaan',
        About_Us_desc: 'Tentang Kami',
        Social_Media_desc: 'Media Sosial',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Pernyataan Penting:',
        Limited_desc: 'FD121 Limited adalah perusahaan teknologi keuangan, bukan bank. Layanan penitipan cadangan disediakan oleh ',
        First_desc: "First Digital Trust Limited",
        product_desc: 'Semua nama produk dan perusahaan adalah merek dagang™ atau merek dagang terdaftar® dari pemegangnya masing-masing. Semua nama perusahaan, produk, dan layanan yang digunakan dalam situs web ini hanya untuk tujuan identifikasi. Penggunaan nama, merek dagang, dan merek dagang tersebut tidak menyiratkan adanya afiliasi atau dukungan oleh mereka.',
        additional_desc: 'Untuk risiko, pengungkapan, dan informasi penting tambahan, silakan kunjungi',
        Copyright_desc: 'Hak Cipta © 2024 FD121 Limited. Seluruh Hak Dilindungi.'
    },
    modal: {
        hold_desc: 'Jika Anda memegang',
        Smart_desc: 'dari BNB Smart Chain, Anda akan mendapatkan bonus hadiah,',
        Rewards_desc: 'Hadiah hanya tersedia untuk jumlah yang melebihi',
        address_desc: 'Alamat ini memegang',
        hours_desc: 'selama 24 jam, dan jumlah minimum yang harus dipertahankan didasarkan pada snapshot acak sistem. Jumlah dialokasikan setiap 24 jam. Penetapan aktual ke akun yang bersangkutan akan berlaku.'
    },
    UserInfo: {
        Wallets_desc: 'Dompet',
        Reward_desc: 'Hadiah',
        half_month: '15 Hari',
        one_month: '1 bulan',
        three_month: '3 bulan',
        Invitees: 'Undangan',
        no_data: 'Tidak Ada Data',
        walletNo_support: 'Dompet Anda tidak didukung. Silakan gunakan aplikasi dompet lain.',
        network_wrong: 'Harap pilih jaringan yang benar',
      },
}
export default en_ID

// Datajson:{
//     language_json:{
//         zh_json:'China disederhanakan',
//         zh_FT_json: 'Cina Tradisional',
//         eh_json:'Bahasa inggris',
//         ja_JP_json:'Bahasa jepang.',  
//         ko_KR_json:'Korea.' ,
//         en_TH_json:'Bahasa thailand',
//         fr_FR_json:'perancis',
//         ru_RU_json:'rusia',
//         en_IN_json:'Hindi berbicara',
//         en_ID_json:'Bahasa indonesia',
//         en_BS_json:'farsiname',
//         en_YN_json:'Vietnam (bahasa)',
//         en_ALB_json:'Arab.',
//         en_TEQ_json:'Turki (bahasa)',
//         en_ES_json:'spanyol',
//         en_PTY_json:'portugis',
//         en_YDL_json:'Italia.'
//     },
// },