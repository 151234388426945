<template>
  <div id="app">
    <Navtab></Navtab>
    <router-view />
  </div>
</template>
<script>
import Navtab from "@/components/navComom/NavTab.vue";
// import MobileDetect from 'mobile-detect';
// import { getIPDetails, getDevice } from '@/utils/api/api'
export default {
  components: {
    Navtab,
  },
  methods: {},
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
